import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import DeckCardsList from './DeckCardList';
import { RedoOutlined } from '@ant-design/icons';
import { decryptObj, encryptObj } from '../../utils/hashing';

const DeckEdit: React.FC<any> = ({
  decks,
  setDecks,
  selectedCard,
  setCards,
  isXS,
  cards,
  selectedDeck,
  setSelectedDeck,
  isDeckEditing,
  setInputValue,
  setIsAdding,
  setIsBuilding,
  setIsCollapsed,
  setIsDashboard,
  setIsDeckEditing,
  setIsEditing,
  setIsMenuOpen,
  setSelectedCard,
  openNotificationWithIcon,
  inputValue,
  isCollapsed,
  filteredList,
  setFilteredList,
  handleEditDeck,
}) => {
  const id = selectedDeck.id;
  const [form] = Form.useForm();
  const [hash, setHash] = useState(selectedDeck.hash);
  const [name, setName] = useState(selectedDeck.name);
  const [meta, setMeta] = useState(selectedDeck.meta);
  const [list, setList] = useState(selectedDeck.list);
  const [cardList, setCardList] = useState(selectedDeck.list.map(cardId => cards.find(card => Number(card.id) === cardId)));

  useEffect(() => {
    form.setFieldsValue({ ...selectedDeck })
    const deck = {
      id,
      name,
      meta,
      list,
    };
    setHash(encryptObj(deck))
    setName(selectedDeck.name)
    setMeta(selectedDeck.meta)
    setList(selectedDeck.list)
    setCardList(selectedDeck.list.map(cardId => cards.find(card => Number(card.id) === cardId)))
  }, [form, selectedDeck, cards, id, name, meta, list])
  
  const calcDeckHash = () => {
    const deck = {
      id,
      name,
      meta,
      list,
    };
    const enc = encryptObj(deck)
    const dec = decryptObj(enc)
    console.debug(enc, dec)
    setHash(enc)

    form.setFieldsValue({ hash: hash })
  }
  const handleUpdate = (e) => {
    e.preventDefault();

    if (!hash ||
      !id ||
      !name ||
      !meta ||
      !list) {
      openNotificationWithIcon('error:deckedit')
      return null;
    }

    const deck = {
      hash,
      id,
      name,
      meta,
      list,
    };

    for (let i = 0; i < decks.length; i++) {
      if (decks[i].id === id) {
        decks.splice(i, 1, deck);
        break;
      }
    }

    localStorage.setItem('decks_data', JSON.stringify(decks));
    setDecks(decks);
    setIsEditing(false);
    openNotificationWithIcon('success:deckedit')
  };

  return (
    <Col
      span={24}
    >
      <Form
        name="editdeck"
        form={form}
        size={isXS ? 'small' : 'middle'}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        initialValues={{ 
          id,
          name,
          meta,
          list,
        }}
        autoComplete="off"
        style={{ padding: '8px' }}
      >
        <Row style={{ display: 'flex' }} justify={'space-around'}>
          <Card className='glassyformcard' style={{ margin: !isXS ? '8px' : '8px 0', flexGrow: '1' }}>
            <Form.Item
              label={<Space>
                hash 
                <Button 
                  size='small' 
                  icon={<RedoOutlined />}
                  onClick={() => {calcDeckHash()}}
                />
              </Space>}
              name="hash"
              rules={[{ required: true, message: 'Please input your hash!' }]}
            >
              <Input onChange={e => setHash(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="name"
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input onChange={e => setMeta(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="meta"
              name="meta"
              rules={[{ required: true, message: 'Please input your meta!' }]}
            >
              <TextArea
                onChange={e => setMeta(e.target.value)}
                placeholder="Controlled autosize"
                autoSize={{ minRows: 4, maxRows: 5 }}
              />
            </Form.Item>
          </Card>
        </Row>
        <Row style={{ display: 'flex', maxWidth: isCollapsed ? '100vw' : 'calc(100vw - 320px - 16px)' }} justify={'space-around'}>
          <Card className='glassyformcard' style={{ margin: !isXS ? '8px' : '8px 0', flexGrow: '1' }}>
          </Card>
        </Row>
        <Row style={{ display: 'flex', maxWidth: isCollapsed ? '100vw' : 'calc(100vw - 320px - 16px)' }} justify={'space-around'}>
          <Card 
            className='glassyformcard' 
            style={{
              margin: !isXS ? '8px' : '8px 0',  
              flexGrow: '2', 
              padding: '0 0 0 0'
            }}
            bodyStyle={{ 
              padding: '0 0 0 0' 
            }}
          >
            <DeckCardsList
              cards={cardList}
              handleEdit={handleEditDeck}
              setSelectedCard={setSelectedCard}
              inputValue={inputValue}
              isCollapsed={isCollapsed}
              filteredList={filteredList}
              setFilteredList={setFilteredList}
              isXS={isXS}
              setIsMenuOpen={setIsMenuOpen}
            />
          </Card>
        </Row>
        <Row 
          style={{ display: 'flex', padding: !isXS ? '8px' : '8px 0', maxWidth: isCollapsed ? '100vw' : 'calc(100vw - 320px - 16px)' }} 
          justify={"center"}
        >
          <Button 
            type="primary" 
            htmlType="submit" 
            onClick={handleUpdate}
          >
            Submit
          </Button>
          <Button 
            htmlType="button" 
            onClick={() => {
              setIsBuilding(true)
              setIsDeckEditing(false)
              setIsAdding(false)
              setIsDashboard(false)
            }}
          >
            Cancel
          </Button>
        </Row>
      </Form>
    </Col>
  );
};

export default DeckEdit;