import { List, Card, Image, ConfigProvider } from "antd";
import React from "react";

const CardItem = ({
  item,
  handleEdit = null,
  setSelectedCard = null,
  isActionsDisabled = false,
  isInList = false,
  padding = '.5em',
  is3D = true,
  isXS = false,
  setIsMenuOpen,
}) => {
  const [visible, setVisible] = React.useState(false);
  const [scaleStep, setScaleStep] = React.useState(.8);
  const imUrl = ('/img/' + item.image).replace('.png','.webp')

  React.useEffect(() => {
    setScaleStep(.8)
  }, [])

  const card = (<Card
    className="risecard"
    loading={!item}
    bodyStyle={{ padding: "0" }}
    cover={
      <Image
        style={{
          objectFit: 'cover',
        }}
        loading="lazy"
        wrapperStyle={{width: '100%'}}
        preview={{
          visible: (visible),
          maxScale: 3,
          mask: false,
          movable: true,
          scaleStep,
          src: imUrl,
          height: '90%',
          width: 'auto',
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
        src={imUrl}
        alt={item.name + ' - Rank ' + item.rank}
        fallback='./card_back.webp'
      />
    }
  />)

  const cardNoPreview = (<Card
    className="risecard"
    loading={!item}
    bodyStyle={{ padding: "0" }}
    cover={
      <Image
        style={{
          objectFit: 'cover',
        }}
        loading="lazy"
        wrapperStyle={{width: '100%'}}
        preview={false}
        src={imUrl}
        alt={item.name + ' - Rank ' + item.rank}
        fallback='./card_back.webp'
      />
    }
  />)

  const card3DWrapper = (card) => (<div
    className="pre-container css-only"
    onDoubleClick={(event) => {
      if (isActionsDisabled) return;
      event.preventDefault();
      handleEdit(item.id);
    }}
    onClick={(event) => {
      if (isActionsDisabled) return;
      event.preventDefault();
      setSelectedCard(item);
      setIsMenuOpen(false);
    }}
    onContextMenu={(event) => {
      if (isActionsDisabled) return;
      event.preventDefault();
      setVisible(true);
      setIsMenuOpen(false);
    }}
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <pre
      className="language-css"
      tabIndex={0}
    >
      {card}
    </pre>
  </div>)

const cardMobileWrapper = (card) => (<div
  className="pre-container css-only"
  onDoubleClick={() => {
    if (isActionsDisabled) return;
    handleEdit(item.id);
  }}
  onClick={() => {
    if (isActionsDisabled) return;
    setSelectedCard(item);
  }}
  onContextMenu={(event) => {
    if (isActionsDisabled) return;
    event.preventDefault()
    setVisible(true)
  }}
>
  <pre
    className="language-css"
    tabIndex={0}
  >
    {cardNoPreview}
  </pre>
</div>)

  return (<ConfigProvider
    theme={{
      components: {
        Image: {
          previewOperationHoverColor: "rgba(255, 255, 255, 0.35)"
        },
      },
    }}
  >
    <List.Item
      style={{
        display: "flex",
        justifyContent: 'space-evenly',
        listStyleType: 'none',
        padding: padding
      }}
    >
      {is3D && !isXS ?
        card3DWrapper(card) :
        isXS ? 
          cardMobileWrapper(card) :
          card}
    </List.Item>
  </ConfigProvider>);
};

export default CardItem;