import { CardData, DeckData } from "../type";
import cardsText from "./cardsText.json";

const cardsData: Array<CardData> = [
  {
    "hash": "XXXXXXXXX",
    "id": "1",
    "set": "ASSE",
    "number": "1",
    "name": "Ether",
    "rarity": "Common",
    "rank": "1",
    "image": "001_ether_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Ether",
    "meta": cardsText["001_ether_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "2",
    "set": "ASSE",
    "number": "2",
    "name": "Stock",
    "rarity": "Common",
    "rank": "1",
    "image": "002_stock_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Stock",
    "meta": cardsText["002_stock_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "3",
    "set": "ASSE",
    "number": "3",
    "name": "Atlas",
    "rarity": "Rare",
    "rank": "1",
    "image": "003_atlas_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["003_atlas_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "4",
    "set": "ASSE",
    "number": "3",
    "name": "Atlas (Alt) closeUpFace",
    "rarity": "Rare",
    "rank": "1",
    "image": "003_atlasV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["003_atlasV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "5",
    "set": "ASSE",
    "number": "3",
    "name": "Atlas (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "003_atlasV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["003_atlasV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "6",
    "set": "ASSE",
    "number": "4",
    "name": "Azell",
    "rarity": "Rare",
    "rank": "1",
    "image": "004_azell_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["004_azell_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "7",
    "set": "ASSE",
    "number": "4",
    "name": "Azell (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "004_azellV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["004_azellV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "8",
    "set": "ASSE",
    "number": "5",
    "name": "Lazarus",
    "rarity": "Rare",
    "rank": "1",
    "image": "005_lazarus_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["005_lazarus_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "9",
    "set": "ASSE",
    "number": "5",
    "name": "Lazarus (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "005_lazarusV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["005_lazarusV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "10",
    "set": "ASSE",
    "number": "6",
    "name": "Lilith",
    "rarity": "Rare",
    "rank": "1",
    "image": "006_lilith_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["006_lilith_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "11",
    "set": "ASSE",
    "number": "6",
    "name": "Lilith (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "006_lilithV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["006_lilithV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "12",
    "set": "ASSE",
    "number": "7",
    "name": "Tyran",
    "rarity": "Rare",
    "rank": "1",
    "image": "007_tyran_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["007_tyran_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "13",
    "set": "ASSE",
    "number": "7",
    "name": "Tyran (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "007_tyranV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["007_tyranV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "14",
    "set": "ASSE",
    "number": "8",
    "name": "R_182",
    "rarity": "Rare",
    "rank": "1",
    "image": "008_r_182_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["008_r_182_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "15",
    "set": "ASSE",
    "number": "8",
    "name": "R_182 (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "008_r_182V2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["008_r_182V2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "16",
    "set": "ASSE",
    "number": "9",
    "name": "Ollaf & Ollia",
    "rarity": "Rare",
    "rank": "1",
    "image": "009_ollaf_ollia_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["009_ollaf_ollia_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "17",
    "set": "ASSE",
    "number": "9",
    "name": "Ollaf & Ollia (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "009_ollaf_olliaV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["009_ollaf_olliaV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "18",
    "set": "ASSE",
    "number": "10",
    "name": "Keyng The Great",
    "rarity": "Rare",
    "rank": "1",
    "image": "010_keyng_the_great_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["010_keyng_the_great_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "19",
    "set": "ASSE",
    "number": "10",
    "name": "Keyng The Great (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "010_keyng_the_greatV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["010_keyng_the_greatV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "20",
    "set": "ASSE",
    "number": "11",
    "name": "The Unslain",
    "rarity": "Rare",
    "rank": "1",
    "image": "011_the_unslain_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["011_the_unslain_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "21",
    "set": "ASSE",
    "number": "11",
    "name": "The Unslain (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "011_the_unslainV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["011_the_unslainV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "22",
    "set": "ASSE",
    "number": "12",
    "name": "Yzate",
    "rarity": "Rare",
    "rank": "1",
    "image": "012_yzate_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["012_yzate_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "23",
    "set": "ASSE",
    "number": "12",
    "name": "Yzate (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "012_yzateV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["012_yzateV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "24",
    "set": "ASSE",
    "number": "13",
    "name": "Ozate",
    "rarity": "Rare",
    "rank": "1",
    "image": "013_ozate_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["013_ozate_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "25",
    "set": "ASSE",
    "number": "13",
    "name": "Ozate (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "013_ozateV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["013_ozateV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "26",
    "set": "ASSE",
    "number": "14",
    "name": "Holo_Clone",
    "rarity": "Rare",
    "rank": "1",
    "image": "014_holo_clone_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["014_holo_clone_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "27",
    "set": "ASSE",
    "number": "14",
    "name": "Holo_Clone (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "014_holo_cloneV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["014_holo_cloneV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "28",
    "set": "ASSE",
    "number": "15",
    "name": "Alior Dagnus",
    "rarity": "Rare",
    "rank": "1",
    "image": "015_alior_dagnus_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["015_alior_dagnus_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "29",
    "set": "ASSE",
    "number": "15",
    "name": "Alior Dagnus (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "015_alior_dagnusV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["015_alior_dagnusV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "30",
    "set": "ASSE",
    "number": "16",
    "name": "Harnya The Satyr",
    "rarity": "Rare",
    "rank": "1",
    "image": "016_harnya_the_satyr_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["016_harnya_the_satyr_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "31",
    "set": "ASSE",
    "number": "16",
    "name": "Harnya The Satyr (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "016_harnya_the_satyrV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["016_harnya_the_satyrV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "32",
    "set": "ASSE",
    "number": "17",
    "name": "Lauviah",
    "rarity": "Rare",
    "rank": "1",
    "image": "017_lauviah_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["017_lauviah_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "33",
    "set": "ASSE",
    "number": "17",
    "name": "Lauviah (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "017_lauviahV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["017_lauviahV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "34",
    "set": "ASSE",
    "number": "18",
    "name": "Hermetic Champion",
    "rarity": "Rare",
    "rank": "1",
    "image": "018_hermetic_champion_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["018_hermetic_champion_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "35",
    "set": "ASSE",
    "number": "18",
    "name": "Hermetic Champion (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "018_hermetic_championV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["018_hermetic_championV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "36",
    "set": "ASSE",
    "number": "19",
    "name": "Myrthil",
    "rarity": "Rare",
    "rank": "1",
    "image": "019_myrthil_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["019_myrthil_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "37",
    "set": "ASSE",
    "number": "19",
    "name": "Myrthil (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "019_myrthilV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["019_myrthilV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "38",
    "set": "ASSE",
    "number": "20",
    "name": "Lady Elzeen",
    "rarity": "Rare",
    "rank": "1",
    "image": "020_lady_elzeen_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["020_lady_elzeen_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "39",
    "set": "ASSE",
    "number": "20",
    "name": "Lady Elzeen (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "020_lady_elzeenV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Summoner",
    "meta": cardsText["020_lady_elzeenV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "40",
    "set": "ASSE",
    "number": "21",
    "name": "Portal",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "021_portal_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["021_portal_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "41",
    "set": "ASSE",
    "number": "22",
    "name": "On Fire",
    "rarity": "Rare",
    "rank": "1",
    "image": "022_on_fire_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["022_on_fire_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "42",
    "set": "ASSE",
    "number": "23",
    "name": "Gold Mine",
    "rarity": "Rare",
    "rank": "1",
    "image": "023_gold_mine_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["023_gold_mine_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "43",
    "set": "ASSE",
    "number": "24",
    "name": "Door of Destiny",
    "rarity": "Rare",
    "rank": "1",
    "image": "024_door_of_destiny_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["024_door_of_destiny_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "44",
    "set": "ASSE",
    "number": "25",
    "name": "Decoy Door",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "025_decoy_door_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["025_decoy_door_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "45",
    "set": "ASSE",
    "number": "26",
    "name": "Winter Door",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "026_winter_door_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["026_winter_door_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "46",
    "set": "ASSE",
    "number": "27",
    "name": "Door to Heaven",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "027_door_to_heaven_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["027_door_to_heaven_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "47",
    "set": "ASSE",
    "number": "28",
    "name": "Catacombs",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "028_catacombs_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["028_catacombs_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "48",
    "set": "ASSE",
    "number": "29",
    "name": "Ease Door",
    "rarity": "Common",
    "rank": "2",
    "image": "029_ease_door_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Door",
    "meta": cardsText["029_ease_door_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "49",
    "set": "ASSE",
    "number": "30",
    "name": "Mirror Door",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "030_mirror_door_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Door",
    "meta": cardsText["030_mirror_door_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "50",
    "set": "ASSE",
    "number": "31",
    "name": "Pandora's Door",
    "rarity": "Common",
    "rank": "3",
    "image": "031_pandoras_door_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["031_pandoras_door_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "51",
    "set": "ASSE",
    "number": "32",
    "name": "Library Master",
    "rarity": "Rare",
    "rank": "1",
    "image": "032_library_master_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["032_library_master_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "52",
    "set": "ASSE",
    "number": "33",
    "name": "Master Chef",
    "rarity": "Legendary",
    "rank": "1",
    "image": "033_master_chef_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["033_master_chef_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "53",
    "set": "ASSE",
    "number": "34",
    "name": "Thorn The Keymaster",
    "rarity": "Rare",
    "rank": "1",
    "image": "034_thorn_the_keymaster_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["034_thorn_the_keymaster_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "54",
    "set": "ASSE",
    "number": "35",
    "name": "Alpha",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "035_alpha_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["035_alpha_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "55",
    "set": "ASSE",
    "number": "36",
    "name": "Omega",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "036_omega_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["036_omega_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "56",
    "set": "ASSE",
    "number": "37",
    "name": "Badass Clone",
    "rarity": "Rare",
    "rank": "1",
    "image": "037_badass_clone_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["037_badass_clone_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "57",
    "set": "ASSE",
    "number": "38",
    "name": "Polymorph",
    "rarity": "Legendary",
    "rank": "1",
    "image": "038_polymorph_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Creature",
    "meta": cardsText["038_polymorph_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "58",
    "set": "ASSE",
    "number": "39",
    "name": "Chronos",
    "rarity": "Rare",
    "rank": "1",
    "image": "039_chronos_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["039_chronos_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "59",
    "set": "ASSE",
    "number": "40",
    "name": "Miel",
    "rarity": "Rare",
    "rank": "1",
    "image": "040_miel_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["040_miel_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "60",
    "set": "ASSE",
    "number": "41",
    "name": "El Diablo",
    "rarity": "Rare",
    "rank": "1",
    "image": "041_el_diablo_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["041_el_diablo_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "61",
    "set": "ASSE",
    "number": "42",
    "name": "Angel of Truth",
    "rarity": "Rare",
    "rank": "1",
    "image": "042_angel_of_truth_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["042_angel_of_truth_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "62",
    "set": "ASSE",
    "number": "43",
    "name": "White Flame",
    "rarity": "Rare",
    "rank": "1",
    "image": "043_white_flame_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["043_white_flame_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "63",
    "set": "ASSE",
    "number": "44",
    "name": "Necromancer Skeleton",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "044_necromancer_skeleton_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["044_necromancer_skeleton_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "64",
    "set": "ASSE",
    "number": "45",
    "name": "Skeleton King",
    "rarity": "Rare",
    "rank": "1",
    "image": "045_skeleton_king_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["045_skeleton_king_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "65",
    "set": "ASSE",
    "number": "46",
    "name": "Ocnar's Orb",
    "rarity": "Rare",
    "rank": "2",
    "image": "046_ocnars_orb_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["046_ocnars_orb_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "66",
    "set": "ASSE",
    "number": "47",
    "name": "Vehuiah",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "047_vehuiah_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["047_vehuiah_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "67",
    "set": "ASSE",
    "number": "48",
    "name": "Demonic Skull",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "048_demonic_skull_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["048_demonic_skull_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "68",
    "set": "ASSE",
    "number": "49",
    "name": "Bounty Hunter",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "049_bounty_hunter_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["049_bounty_hunter_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "69",
    "set": "ASSE",
    "number": "50",
    "name": "Wolam",
    "rarity": "Common",
    "rank": "2",
    "image": "050_wolam_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["050_wolam_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "70",
    "set": "ASSE",
    "number": "51",
    "name": "Lance",
    "rarity": "Rare",
    "rank": "2",
    "image": "051_lance_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["051_lance_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "71",
    "set": "ASSE",
    "number": "52",
    "name": "Clone General",
    "rarity": "Rare",
    "rank": "2",
    "image": "052_clone_general_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["052_clone_general_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "72",
    "set": "ASSE",
    "number": "53",
    "name": "Unicorn Rider",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "053_unicorn_rider_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["053_unicorn_rider_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "73",
    "set": "ASSE",
    "number": "54",
    "name": "Z Colossal",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "054_z_colossal_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["054_z_colossal_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "74",
    "set": "ASSE",
    "number": "55",
    "name": "Gold Eater",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "055_gold_eater_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["055_gold_eater_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "75",
    "set": "ASSE",
    "number": "56",
    "name": "Electronic Brain",
    "rarity": "Common",
    "rank": "2",
    "image": "056_electronic_brain_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["056_electronic_brain_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "76",
    "set": "ASSE",
    "number": "57",
    "name": "Ultra_Laser Unicorn",
    "rarity": "Common",
    "rank": "2",
    "image": "057_ultra_laser_unicorn_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["057_ultra_laser_unicorn_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "77",
    "set": "ASSE",
    "number": "58",
    "name": "Felix The Destructor",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "058_felix_the_destructor_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["058_felix_the_destructor_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "78",
    "set": "ASSE",
    "number": "59",
    "name": "Robot Morpher",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "059_robot_morpher_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["059_robot_morpher_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "79",
    "set": "ASSE",
    "number": "60",
    "name": "Angelic Morpher",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "060_angelic_morpher_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["060_angelic_morpher_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "80",
    "set": "ASSE",
    "number": "61",
    "name": "Clone Morpher",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "061_clone_morpher_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["061_clone_morpher_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "81",
    "set": "ASSE",
    "number": "62",
    "name": "Undead Morpher",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "062_undead_morpher_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["062_undead_morpher_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "82",
    "set": "ASSE",
    "number": "63",
    "name": "Global Morpher",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "063_global_morpher_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["063_global_morpher_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "83",
    "set": "ASSE",
    "number": "64",
    "name": "Cutest Kitty",
    "rarity": "Rare",
    "rank": "2",
    "image": "064_cutest_kitty_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["064_cutest_kitty_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "84",
    "set": "ASSE",
    "number": "65",
    "name": "Wild Cat",
    "rarity": "Common",
    "rank": "2",
    "image": "065_wild_cat_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["065_wild_cat_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "85",
    "set": "ASSE",
    "number": "66",
    "name": "Keynok",
    "rarity": "Rare",
    "rank": "2",
    "image": "066_keynok_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["066_keynok_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "86",
    "set": "ASSE",
    "number": "67",
    "name": "Unicity",
    "rarity": "Rare",
    "rank": "2",
    "image": "067_unicity_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["067_unicity_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "87",
    "set": "ASSE",
    "number": "68",
    "name": "Malycia",
    "rarity": "Rare",
    "rank": "3",
    "image": "068_malycia_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["068_malycia_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "88",
    "set": "ASSE",
    "number": "69",
    "name": "Clone Sergeant",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "069_clone_sergeant_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["069_clone_sergeant_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "89",
    "set": "ASSE",
    "number": "70",
    "name": "Clone Bomber",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "070_clone_bomber_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["070_clone_bomber_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "90",
    "set": "ASSE",
    "number": "71",
    "name": "Z Gunner",
    "rarity": "Common",
    "rank": "3",
    "image": "071_z_gunner_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Creature",
    "meta": cardsText["071_z_gunner_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "91",
    "set": "ASSE",
    "number": "72",
    "name": "Z Dogs",
    "rarity": "Common",
    "rank": "3",
    "image": "072_z_dogs_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["072_z_dogs_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "92",
    "set": "ASSE",
    "number": "73",
    "name": "Grave Robber",
    "rarity": "Common",
    "rank": "3",
    "image": "073_grave_robber_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["073_grave_robber_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "93",
    "set": "ASSE",
    "number": "74",
    "name": "Grave Digger",
    "rarity": "Common",
    "rank": "3",
    "image": "074_grave_digger_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["074_grave_digger_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "94",
    "set": "ASSE",
    "number": "75",
    "name": "Ravenous Imp",
    "rarity": "Common",
    "rank": "3",
    "image": "075_ravenous_imp_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["075_ravenous_imp_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "95",
    "set": "ASSE",
    "number": "76",
    "name": "Vacuum Cleaner From Hell",
    "rarity": "Common",
    "rank": "3",
    "image": "076_vacuum_cleaner_from_hell_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["076_vacuum_cleaner_from_hell_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "96",
    "set": "ASSE",
    "number": "77",
    "name": "Flying Mistigri",
    "rarity": "Common",
    "rank": "3",
    "image": "077_flying_mistigri_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["077_flying_mistigri_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "97",
    "set": "ASSE",
    "number": "78",
    "name": "Flying Skeleton",
    "rarity": "Common",
    "rank": "3",
    "image": "078_flying_skeleton_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["078_flying_skeleton_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "98",
    "set": "ASSE",
    "number": "79",
    "name": "Proteiform",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "079_proteiform_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["079_proteiform_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "99",
    "set": "ASSE",
    "number": "80",
    "name": "Rank 3 Morpher",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "080_rank_3_morpher_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["080_rank_3_morpher_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "100",
    "set": "ASSE",
    "number": "81",
    "name": "Abariel",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "081_abariel_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["081_abariel_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "101",
    "set": "ASSE",
    "number": "82",
    "name": "Keylaz",
    "rarity": "Common",
    "rank": "3",
    "image": "082_keylaz_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["082_keylaz_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "102",
    "set": "ASSE",
    "number": "83",
    "name": "Soldier in Armor",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "083_soldier_in_armor_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["083_soldier_in_armor_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "103",
    "set": "ASSE",
    "number": "84",
    "name": "Z Destructor",
    "rarity": "Common",
    "rank": "4",
    "image": "084_z_destructor_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["084_z_destructor_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "104",
    "set": "ASSE",
    "number": "85",
    "name": "Syllile",
    "rarity": "Common",
    "rank": "4",
    "image": "085_syllile_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["085_syllile_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "105",
    "set": "ASSE",
    "number": "86",
    "name": "Z Howler",
    "rarity": "Common",
    "rank": "4",
    "image": "086_z_howler_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["086_z_howler_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "106",
    "set": "ASSE",
    "number": "87",
    "name": "Rank 4 Morpher",
    "rarity": "Common",
    "rank": "4",
    "image": "087_rank_4_morpher_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["087_rank_4_morpher_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "107",
    "set": "ASSE",
    "number": "88",
    "name": "Tormented Soul",
    "rarity": "Common",
    "rank": "4",
    "image": "088_tormented_soul_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["088_tormented_soul_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "108",
    "set": "ASSE",
    "number": "89",
    "name": "Mimic",
    "rarity": "Rare",
    "rank": "4",
    "image": "089_mimic_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["089_mimic_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "109",
    "set": "ASSE",
    "number": "90",
    "name": "Captive Skeleton",
    "rarity": "Common",
    "rank": "4",
    "image": "090_captive_skeleton_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["090_captive_skeleton_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "110",
    "set": "ASSE",
    "number": "91",
    "name": "Keynn",
    "rarity": "Common",
    "rank": "4",
    "image": "091_keynn_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["091_keynn_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "111",
    "set": "ASSE",
    "number": "92",
    "name": "Gremlin",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "092_gremlin_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["092_gremlin_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "112",
    "set": "ASSE",
    "number": "93",
    "name": "Pomp Cat",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "093_pomp_cat_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["093_pomp_cat_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "113",
    "set": "ASSE",
    "number": "94",
    "name": "Skeleton",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "094_skeleton_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["094_skeleton_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "114",
    "set": "ASSE",
    "number": "95",
    "name": "Nocturne",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "095_nocturne_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["095_nocturne_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "115",
    "set": "ASSE",
    "number": "96",
    "name": "Basic Clone",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "096_basic_clone_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["096_basic_clone_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "116",
    "set": "ASSE",
    "number": "97",
    "name": "R_01",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "097_r_01_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["097_r_01_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "117",
    "set": "ASSE",
    "number": "98",
    "name": "Guni",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "098_guni_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["098_guni_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "118",
    "set": "ASSE",
    "number": "99",
    "name": "Bilious Beast",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "099_bilious_beast_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["099_bilious_beast_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "119",
    "set": "ASSE",
    "number": "100",
    "name": "The Exhumed",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "100_the_exhumed_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["100_the_exhumed_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "120",
    "set": "ASSE",
    "number": "101",
    "name": "Lazy Z",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "101_lazy_z_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["101_lazy_z_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "121",
    "set": "ASSE",
    "number": "102",
    "name": "Hard Skin",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "102_hard_skin_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["102_hard_skin_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "122",
    "set": "ASSE",
    "number": "103",
    "name": "Feather of Light",
    "rarity": "Rare",
    "rank": "1",
    "image": "103_feather_of_light_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["103_feather_of_light_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "123",
    "set": "ASSE",
    "number": "104",
    "name": "Illumination",
    "rarity": "Rare",
    "rank": "1",
    "image": "104_illumination_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["104_illumination_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "124",
    "set": "ASSE",
    "number": "105",
    "name": "The Fall",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "105_the_fall_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["105_the_fall_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "125",
    "set": "ASSE",
    "number": "106",
    "name": "At The Dead End",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "106_at_the_dead_end_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["106_at_the_dead_end_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "126",
    "set": "ASSE",
    "number": "107",
    "name": "Holy Skull",
    "rarity": "Rare",
    "rank": "1",
    "image": "107_holy_skull_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["107_holy_skull_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "127",
    "set": "ASSE",
    "number": "108",
    "name": "Blast Corporation",
    "rarity": "Rare",
    "rank": "1",
    "image": "108_blast_corporation_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["108_blast_corporation_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "128",
    "set": "ASSE",
    "number": "109",
    "name": "Irrational",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "109_irrational_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["109_irrational_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "129",
    "set": "ASSE",
    "number": "110",
    "name": "Rational",
    "rarity": "Rare",
    "rank": "1",
    "image": "110_rational_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["110_rational_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "130",
    "set": "ASSE",
    "number": "111",
    "name": "Maximum Level",
    "rarity": "Rare",
    "rank": "1",
    "image": "111_maximum_level_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["111_maximum_level_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "131",
    "set": "ASSE",
    "number": "112",
    "name": "Salt",
    "rarity": "Rare",
    "rank": "1",
    "image": "112_salt_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["112_salt_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "132",
    "set": "ASSE",
    "number": "113",
    "name": "Ancient's Grimoire",
    "rarity": "Legendary",
    "rank": "1",
    "image": "113_ancients_grimoire_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["113_ancients_grimoire_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "133",
    "set": "ASSE",
    "number": "114",
    "name": "Prophecy of Injustice",
    "rarity": "Rare",
    "rank": "1",
    "image": "114_prophecy_of_injustice_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["114_prophecy_of_injustice_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "134",
    "set": "ASSE",
    "number": "115",
    "name": "Shroud Prophecy",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "115_shroud_prophecy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["115_shroud_prophecy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "135",
    "set": "ASSE",
    "number": "116",
    "name": "Renewal Prophecy",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "116_renewal_prophecy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["116_renewal_prophecy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "136",
    "set": "ASSE",
    "number": "117",
    "name": "Resurrection Prophecy",
    "rarity": "Rare",
    "rank": "1",
    "image": "117_resurrection_prophecy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["117_resurrection_prophecy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "137",
    "set": "ASSE",
    "number": "118",
    "name": "Wealth Prophecy",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "118_wealth_prophecy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["118_wealth_prophecy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "138",
    "set": "ASSE",
    "number": "119",
    "name": "Growth Prophecy",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "119_growth_prophecy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["119_growth_prophecy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "139",
    "set": "ASSE",
    "number": "120",
    "name": "Undead Prophecy",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "120_undead_prophecy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["120_undead_prophecy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "140",
    "set": "ASSE",
    "number": "121",
    "name": "Weakness Prophecy",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "121_weakness_prophecy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["121_weakness_prophecy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "141",
    "set": "ASSE",
    "number": "122",
    "name": "Collapse Prophecy",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "122_collapse_prophecy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["122_collapse_prophecy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "142",
    "set": "ASSE",
    "number": "123",
    "name": "Generosity Prophecy",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "123_generosity_prophecy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Imminent",
    "meta": cardsText["123_generosity_prophecy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "143",
    "set": "ASSE",
    "number": "124",
    "name": "Antimatter",
    "rarity": "Rare",
    "rank": "1",
    "image": "124_antimatter_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["124_antimatter_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "144",
    "set": "ASSE",
    "number": "125",
    "name": "Eleanor's Hand",
    "rarity": "Legendary",
    "rank": "1",
    "image": "125_eleanors_hand_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["125_eleanors_hand_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "145",
    "set": "ASSE",
    "number": "126",
    "name": "Divine Hunt",
    "rarity": "Rare",
    "rank": "1",
    "image": "126_divine_hunt_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["126_divine_hunt_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "146",
    "set": "ASSE",
    "number": "127",
    "name": "Last Hope",
    "rarity": "Rare",
    "rank": "1",
    "image": "127_last_hope_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["127_last_hope_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "147",
    "set": "ASSE",
    "number": "128",
    "name": "Devastation",
    "rarity": "Rare",
    "rank": "1",
    "image": "128_devastation_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["128_devastation_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "148",
    "set": "ASSE",
    "number": "129",
    "name": "Pepper",
    "rarity": "Rare",
    "rank": "1",
    "image": "129_pepper_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["129_pepper_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "149",
    "set": "ASSE",
    "number": "130",
    "name": "Closure",
    "rarity": "Common",
    "rank": "2",
    "image": "130_closure_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["130_closure_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "150",
    "set": "ASSE",
    "number": "131",
    "name": "Pandemic",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "131_pandemic_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["131_pandemic_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "151",
    "set": "ASSE",
    "number": "132",
    "name": "Precognition",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "132_precognition_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["132_precognition_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "152",
    "set": "ASSE",
    "number": "133",
    "name": "Frozen",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "133_frozen_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["133_frozen_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "153",
    "set": "ASSE",
    "number": "134",
    "name": "Destiny",
    "rarity": "Rare",
    "rank": "2",
    "image": "134_destiny_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["134_destiny_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "154",
    "set": "ASSE",
    "number": "135",
    "name": "Regression",
    "rarity": "Common",
    "rank": "2",
    "image": "135_regression_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["135_regression_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "155",
    "set": "ASSE",
    "number": "136",
    "name": "Obscurantism",
    "rarity": "Common",
    "rank": "2",
    "image": "136_obscurantism_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["136_obscurantism_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "156",
    "set": "ASSE",
    "number": "137",
    "name": "Hecatomb",
    "rarity": "Rare",
    "rank": "2",
    "image": "137_hecatomb_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["137_hecatomb_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "157",
    "set": "ASSE",
    "number": "138",
    "name": "Passionate",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "138_passionate_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["138_passionate_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "158",
    "set": "ASSE",
    "number": "139",
    "name": "Second Breath",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "139_second_breath_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["139_second_breath_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "159",
    "set": "ASSE",
    "number": "140",
    "name": "Divine Dance",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "140_divine_dance_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["140_divine_dance_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "160",
    "set": "ASSE",
    "number": "141",
    "name": "Earthquake",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "141_earthquake_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["141_earthquake_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "161",
    "set": "ASSE",
    "number": "142",
    "name": "Slime Slug",
    "rarity": "Rare",
    "rank": "2",
    "image": "142_slime_slug_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["142_slime_slug_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "162",
    "set": "ASSE",
    "number": "143",
    "name": "Pacifism",
    "rarity": "Common",
    "rank": "2",
    "image": "143_pacifism_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["143_pacifism_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "163",
    "set": "ASSE",
    "number": "144",
    "name": "Restoration",
    "rarity": "Common",
    "rank": "2",
    "image": "144_restoration_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["144_restoration_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "164",
    "set": "ASSE",
    "number": "145",
    "name": "Domain Of The Dead",
    "rarity": "Common",
    "rank": "2",
    "image": "145_domain_of_the_dead_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["145_domain_of_the_dead_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "165",
    "set": "ASSE",
    "number": "146",
    "name": "Make A Wish",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "146_make_a_wish_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["146_make_a_wish_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "166",
    "set": "ASSE",
    "number": "147",
    "name": "Sorcery",
    "rarity": "Rare",
    "rank": "2",
    "image": "147_sorcery_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["147_sorcery_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "167",
    "set": "ASSE",
    "number": "148",
    "name": "Felicity",
    "rarity": "Rare",
    "rank": "2",
    "image": "148_felicity_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["148_felicity_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "168",
    "set": "ASSE",
    "number": "149",
    "name": "Abduction",
    "rarity": "Rare",
    "rank": "2",
    "image": "149_abduction_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["149_abduction_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "169",
    "set": "ASSE",
    "number": "150",
    "name": "No Way",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "150_no_way_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["150_no_way_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "170",
    "set": "ASSE",
    "number": "151",
    "name": "Mute",
    "rarity": "Common",
    "rank": "2",
    "image": "151_mute_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["151_mute_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "171",
    "set": "ASSE",
    "number": "152",
    "name": "Ultimate Success",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "152_ultimate_success_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["152_ultimate_success_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "172",
    "set": "ASSE",
    "number": "153",
    "name": "Elevation",
    "rarity": "Common",
    "rank": "2",
    "image": "153_elevation_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["153_elevation_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "173",
    "set": "ASSE",
    "number": "154",
    "name": "Serious Exploration",
    "rarity": "Rare",
    "rank": "2",
    "image": "154_serious_exploration_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["154_serious_exploration_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "174",
    "set": "ASSE",
    "number": "155",
    "name": "Dynamite",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "155_dynamite_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["155_dynamite_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "175",
    "set": "ASSE",
    "number": "156",
    "name": "Healing Potion",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "156_healing_potion_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["156_healing_potion_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "176",
    "set": "ASSE",
    "number": "157",
    "name": "Donut",
    "rarity": "Rare",
    "rank": "2",
    "image": "157_donut_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["157_donut_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "177",
    "set": "ASSE",
    "number": "158",
    "name": "Contamination",
    "rarity": "Common",
    "rank": "3",
    "image": "158_contamination_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["158_contamination_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "178",
    "set": "ASSE",
    "number": "159",
    "name": "Icons of Destiny",
    "rarity": "Common",
    "rank": "3",
    "image": "159_icons_of_destiny_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["159_icons_of_destiny_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "179",
    "set": "ASSE",
    "number": "160",
    "name": "Mask of Darkness",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "160_mask_of_darkness_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["160_mask_of_darkness_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "180",
    "set": "ASSE",
    "number": "161",
    "name": "Electric Orb",
    "rarity": "Rare",
    "rank": "3",
    "image": "161_electric_orb_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["161_electric_orb_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "181",
    "set": "ASSE",
    "number": "162",
    "name": "Madness",
    "rarity": "Rare",
    "rank": "3",
    "image": "162_madness_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["162_madness_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "182",
    "set": "ASSE",
    "number": "163",
    "name": "Treasure",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "163_treasure_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["163_treasure_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "183",
    "set": "ASSE",
    "number": "164",
    "name": "Clone Power",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "164_clone_power_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["164_clone_power_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "184",
    "set": "ASSE",
    "number": "165",
    "name": "Cursed Chest",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "165_cursed_chest_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["165_cursed_chest_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "185",
    "set": "ASSE",
    "number": "166",
    "name": "Mind Control",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "166_mind_control_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["166_mind_control_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "186",
    "set": "ASSE",
    "number": "167",
    "name": "Golden Mirror",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "167_golden_mirror_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["167_golden_mirror_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "187",
    "set": "ASSE",
    "number": "168",
    "name": "Protection Mirror",
    "rarity": "Rare",
    "rank": "3",
    "image": "168_protection_mirror_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["168_protection_mirror_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "188",
    "set": "ASSE",
    "number": "169",
    "name": "Mirror of Ugliness",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "169_mirror_of_ugliness_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["169_mirror_of_ugliness_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "189",
    "set": "ASSE",
    "number": "170",
    "name": "Incubator",
    "rarity": "Common",
    "rank": "3",
    "image": "170_incubator_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["170_incubator_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "190",
    "set": "ASSE",
    "number": "171",
    "name": "Imitation",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "171_imitation_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["171_imitation_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "191",
    "set": "ASSE",
    "number": "172",
    "name": "Echoes",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "172_echoes_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["172_echoes_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "192",
    "set": "ASSE",
    "number": "173",
    "name": "Calm",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "173_calm_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["173_calm_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "193",
    "set": "ASSE",
    "number": "174",
    "name": "Talion",
    "rarity": "Common",
    "rank": "3",
    "image": "174_talion_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["174_talion_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "194",
    "set": "ASSE",
    "number": "175",
    "name": "Blind",
    "rarity": "Common",
    "rank": "3",
    "image": "175_blind_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["175_blind_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "195",
    "set": "ASSE",
    "number": "176",
    "name": "Obligation",
    "rarity": "Rare",
    "rank": "3",
    "image": "176_obligation_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["176_obligation_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "196",
    "set": "ASSE",
    "number": "177",
    "name": "Die And Retry",
    "rarity": "Common",
    "rank": "3",
    "image": "177_die_and_retry_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["177_die_and_retry_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "197",
    "set": "ASSE",
    "number": "178",
    "name": "Casual Browsing",
    "rarity": "Rare",
    "rank": "3",
    "image": "178_casual_browsing_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["178_casual_browsing_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "198",
    "set": "ASSE",
    "number": "179",
    "name": "High Voltage",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "179_high_voltage_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["179_high_voltage_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "199",
    "set": "ASSE",
    "number": "180",
    "name": "Go With The Flow",
    "rarity": "Common",
    "rank": "3",
    "image": "180_go_with_the_flow_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["180_go_with_the_flow_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "200",
    "set": "ASSE",
    "number": "181",
    "name": "Royal Attack",
    "rarity": "Common",
    "rank": "3",
    "image": "181_royal_attack_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["181_royal_attack_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "201",
    "set": "ASSE",
    "number": "182",
    "name": "Royal Protection",
    "rarity": "Common",
    "rank": "3",
    "image": "182_royal_protection_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["182_royal_protection_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "202",
    "set": "ASSE",
    "number": "183",
    "name": "Reverso",
    "rarity": "Rare",
    "rank": "3",
    "image": "183_reverso_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["183_reverso_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "203",
    "set": "ASSE",
    "number": "184",
    "name": "Return",
    "rarity": "Legendary",
    "rank": "3",
    "image": "184_return_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["184_return_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "204",
    "set": "ASSE",
    "number": "185",
    "name": "Rage",
    "rarity": "Common",
    "rank": "4",
    "image": "185_rage_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["185_rage_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "205",
    "set": "ASSE",
    "number": "186",
    "name": "Etherisation",
    "rarity": "Common",
    "rank": "4",
    "image": "186_etherisation_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["186_etherisation_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "206",
    "set": "ASSE",
    "number": "187",
    "name": "Life Cycle",
    "rarity": "Common",
    "rank": "4",
    "image": "187_life_cycle_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["187_life_cycle_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "207",
    "set": "ASSE",
    "number": "188",
    "name": "Common Rest",
    "rarity": "Uncommon",
    "rank": "4",
    "image": "188_common_rest_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["188_common_rest_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "208",
    "set": "ASSE",
    "number": "189",
    "name": "Last Smile",
    "rarity": "Common",
    "rank": "4",
    "image": "189_last_smile_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["189_last_smile_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "209",
    "set": "ASSE",
    "number": "190",
    "name": "Revenge",
    "rarity": "Common",
    "rank": "4",
    "image": "190_revenge_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["190_revenge_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "210",
    "set": "ASSE",
    "number": "191",
    "name": "Disinterest",
    "rarity": "Common",
    "rank": "4",
    "image": "191_disinterest_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["191_disinterest_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "211",
    "set": "ASSE",
    "number": "192",
    "name": "Drop Out",
    "rarity": "Common",
    "rank": "4",
    "image": "192_drop_out_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["192_drop_out_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "212",
    "set": "ASSE",
    "number": "193",
    "name": "Healthy Carrier",
    "rarity": "Common",
    "rank": "4",
    "image": "193_healthy_carrier_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["193_healthy_carrier_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "213",
    "set": "ASSE",
    "number": "194",
    "name": "Key",
    "rarity": "Common",
    "rank": "4",
    "image": "194_key_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["194_key_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "214",
    "set": "ASSE",
    "number": "195",
    "name": "Hammer Of Destruction",
    "rarity": "Common",
    "rank": "4",
    "image": "195_hammer_of_destruction_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["195_hammer_of_destruction_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "215",
    "set": "ASSE",
    "number": "196",
    "name": "Repair Hammer",
    "rarity": "Common",
    "rank": "4",
    "image": "196_repair_hammer_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["196_repair_hammer_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "216",
    "set": "ASSE",
    "number": "197",
    "name": "Money Battle",
    "rarity": "Common",
    "rank": "4",
    "image": "197_money_battle_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["197_money_battle_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "217",
    "set": "ASSE",
    "number": "198",
    "name": "Wanted",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "198_wanted_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["198_wanted_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "218",
    "set": "ASSE",
    "number": "199",
    "name": "Lucky Clover",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "199_lucky_clover_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["199_lucky_clover_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "219",
    "set": "ASSE",
    "number": "200",
    "name": "Key Of Light",
    "rarity": "Rare",
    "rank": "1",
    "image": "200_key_of_light_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["200_key_of_light_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "220",
    "set": "ASSE",
    "number": "201",
    "name": "Golden Clover",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "201_golden_clover_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["201_golden_clover_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "221",
    "set": "ASSE",
    "number": "202",
    "name": "Rest",
    "rarity": "Legendary",
    "rank": "1",
    "image": "202_rest_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["202_rest_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "222",
    "set": "ASSE",
    "number": "203",
    "name": "Cure",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "203_cure_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["203_cure_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "223",
    "set": "ASSE",
    "number": "204",
    "name": "Destructor",
    "rarity": "Rare",
    "rank": "1",
    "image": "204_destructor_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["204_destructor_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "224",
    "set": "ASSE",
    "number": "205",
    "name": "Z Dinner",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "205_z_dinner_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["205_z_dinner_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "225",
    "set": "ASSE",
    "number": "206",
    "name": "Paradise",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "206_paradise_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["206_paradise_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "226",
    "set": "ASSE",
    "number": "207",
    "name": "Showdown",
    "rarity": "Rare",
    "rank": "1",
    "image": "207_showdown_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["207_showdown_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "227",
    "set": "ASSE",
    "number": "208",
    "name": "Dark Temple",
    "rarity": "Rare",
    "rank": "1",
    "image": "208_dark_temple_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["208_dark_temple_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "228",
    "set": "ASSE",
    "number": "209",
    "name": "Greed",
    "rarity": "Rare",
    "rank": "1",
    "image": "209_greed_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["209_greed_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "229",
    "set": "ASSE",
    "number": "210",
    "name": "Envy",
    "rarity": "Rare",
    "rank": "1",
    "image": "210_envy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["210_envy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "230",
    "set": "ASSE",
    "number": "211",
    "name": "Pride",
    "rarity": "Rare",
    "rank": "1",
    "image": "211_pride_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["211_pride_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "231",
    "set": "ASSE",
    "number": "212",
    "name": "Lust",
    "rarity": "Rare",
    "rank": "1",
    "image": "212_lust_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["212_lust_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "232",
    "set": "ASSE",
    "number": "213",
    "name": "Gluttony",
    "rarity": "Rare",
    "rank": "1",
    "image": "213_gluttony_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["213_gluttony_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "233",
    "set": "ASSE",
    "number": "214",
    "name": "Sloth",
    "rarity": "Rare",
    "rank": "1",
    "image": "214_sloth_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["214_sloth_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "234",
    "set": "ASSE",
    "number": "215",
    "name": "Wrath",
    "rarity": "Rare",
    "rank": "1",
    "image": "215_wrath_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["215_wrath_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "235",
    "set": "ASSE",
    "number": "216",
    "name": "Overload",
    "rarity": "Rare",
    "rank": "1",
    "image": "216_overload_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["216_overload_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "236",
    "set": "ASSE",
    "number": "217",
    "name": "Rank 1 Spellcaster",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "217_rank_1_spellcaster_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["217_rank_1_spellcaster_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "237",
    "set": "ASSE",
    "number": "218",
    "name": "Perfectionist Spellcaster",
    "rarity": "Rare",
    "rank": "1",
    "image": "218_perfectionist_spellcaster_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["218_perfectionist_spellcaster_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "238",
    "set": "ASSE",
    "number": "219",
    "name": "Free Coin",
    "rarity": "Legendary",
    "rank": "1",
    "image": "219_free_coin_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["219_free_coin_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "239",
    "set": "ASSE",
    "number": "220",
    "name": "Signet Ring of Sacrifice",
    "rarity": "Rare",
    "rank": "1",
    "image": "220_signet_ring_of_sacrifice_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["220_signet_ring_of_sacrifice_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "240",
    "set": "ASSE",
    "number": "221",
    "name": "Clover Field",
    "rarity": "Rare",
    "rank": "1",
    "image": "221_clover_field_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["221_clover_field_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "241",
    "set": "ASSE",
    "number": "222",
    "name": "Void Energy",
    "rarity": "Rare",
    "rank": "1",
    "image": "222_void_energy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["222_void_energy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "242",
    "set": "ASSE",
    "number": "223",
    "name": "Hourglass",
    "rarity": "Rare",
    "rank": "1",
    "image": "223_hourglass_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["223_hourglass_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "243",
    "set": "ASSE",
    "number": "224",
    "name": "Deny",
    "rarity": "Rare",
    "rank": "1",
    "image": "224_deny_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["224_deny_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "244",
    "set": "ASSE",
    "number": "225",
    "name": "Flesh Ring",
    "rarity": "Rare",
    "rank": "1",
    "image": "225_flesh_ring_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["225_flesh_ring_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "245",
    "set": "ASSE",
    "number": "226",
    "name": "Food Training",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "226_food_training_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["226_food_training_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "246",
    "set": "ASSE",
    "number": "227",
    "name": "Purity",
    "rarity": "Rare",
    "rank": "1",
    "image": "227_purity_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["227_purity_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "247",
    "set": "ASSE",
    "number": "228",
    "name": "Silence",
    "rarity": "Rare",
    "rank": "1",
    "image": "228_silence_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["228_silence_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "248",
    "set": "ASSE",
    "number": "229",
    "name": "Black Hole",
    "rarity": "Rare",
    "rank": "1",
    "image": "229_black_hole_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["229_black_hole_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "249",
    "set": "ASSE",
    "number": "230",
    "name": "Split",
    "rarity": "Legendary",
    "rank": "1",
    "image": "230_split_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["230_split_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "250",
    "set": "ASSE",
    "number": "231",
    "name": "Support",
    "rarity": "Rare",
    "rank": "1",
    "image": "231_support_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["231_support_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "251",
    "set": "ASSE",
    "number": "232",
    "name": "Favor",
    "rarity": "Rare",
    "rank": "1",
    "image": "232_favor_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["232_favor_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "252",
    "set": "ASSE",
    "number": "233",
    "name": "Glowing Protection",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "233_glowing_protection_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["233_glowing_protection_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "253",
    "set": "ASSE",
    "number": "234",
    "name": "Amethyst Signet Ring",
    "rarity": "Rare",
    "rank": "1",
    "image": "234_amethyst_signet_ring_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["234_amethyst_signet_ring_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "254",
    "set": "ASSE",
    "number": "235",
    "name": "Contributor",
    "rarity": "Legendary",
    "rank": "1",
    "image": "235_contributor_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["235_contributor_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "255",
    "set": "ASSE",
    "number": "236",
    "name": "Water Orb",
    "rarity": "Legendary",
    "rank": "1",
    "image": "236_water_orb_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["236_water_orb_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "256",
    "set": "ASSE",
    "number": "237",
    "name": "Light Clover Flower",
    "rarity": "Legendary",
    "rank": "1",
    "image": "237_light_clover_flower_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["237_light_clover_flower_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "257",
    "set": "ASSE",
    "number": "238",
    "name": "Dark Clover",
    "rarity": "Rare",
    "rank": "1",
    "image": "238_dark_clover_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["238_dark_clover_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "258",
    "set": "ASSE",
    "number": "239",
    "name": "Sword Of Destiny",
    "rarity": "Legendary",
    "rank": "1",
    "image": "239_sword_of_destiny_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["239_sword_of_destiny_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "259",
    "set": "ASSE",
    "number": "240",
    "name": "Grim Dance",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "240_grim_dance_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["240_grim_dance_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "260",
    "set": "ASSE",
    "number": "241",
    "name": "Holy Grenade",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "241_holy_grenade_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["241_holy_grenade_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "261",
    "set": "ASSE",
    "number": "242",
    "name": "Eternal Damnation",
    "rarity": "Uncommon",
    "rank": "1",
    "image": "242_eternal_damnation_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["242_eternal_damnation_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "262",
    "set": "ASSE",
    "number": "243",
    "name": "Assassination",
    "rarity": "Rare",
    "rank": "1",
    "image": "243_assassination_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["243_assassination_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "263",
    "set": "ASSE",
    "number": "244",
    "name": "Life Temple",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "244_life_temple_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["244_life_temple_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "264",
    "set": "ASSE",
    "number": "245",
    "name": "Blast Corp. Factory",
    "rarity": "Rare",
    "rank": "2",
    "image": "245_blast_corp_factory_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["245_blast_corp_factory_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "265",
    "set": "ASSE",
    "number": "246",
    "name": "Undead Army",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "246_undead_army_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["246_undead_army_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "266",
    "set": "ASSE",
    "number": "247",
    "name": "Clone Army",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "247_clone_army_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["247_clone_army_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "267",
    "set": "ASSE",
    "number": "248",
    "name": "Telekinesis",
    "rarity": "Rare",
    "rank": "2",
    "image": "248_telekinesis_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["248_telekinesis_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "268",
    "set": "ASSE",
    "number": "249",
    "name": "Serenity",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "249_serenity_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["249_serenity_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "269",
    "set": "ASSE",
    "number": "250",
    "name": "Parasite",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "250_parasite_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["250_parasite_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "270",
    "set": "ASSE",
    "number": "251",
    "name": "Amok",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "251_amok_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["251_amok_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "271",
    "set": "ASSE",
    "number": "252",
    "name": "Dementia",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "252_dementia_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["252_dementia_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "272",
    "set": "ASSE",
    "number": "253",
    "name": "Paranoia",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "253_paranoia_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["253_paranoia_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "273",
    "set": "ASSE",
    "number": "254",
    "name": "Paralysis",
    "rarity": "Common",
    "rank": "2",
    "image": "254_paralysis_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["254_paralysis_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "274",
    "set": "ASSE",
    "number": "255",
    "name": "Schizophrenia",
    "rarity": "Rare",
    "rank": "2",
    "image": "255_schizophrenia_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["255_schizophrenia_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "275",
    "set": "ASSE",
    "number": "256",
    "name": "Cold",
    "rarity": "Common",
    "rank": "2",
    "image": "256_cold_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["256_cold_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "276",
    "set": "ASSE",
    "number": "257",
    "name": "Trauma",
    "rarity": "Rare",
    "rank": "2",
    "image": "257_trauma_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["257_trauma_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "277",
    "set": "ASSE",
    "number": "258",
    "name": "Sado",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "258_sado_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["258_sado_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "278",
    "set": "ASSE",
    "number": "259",
    "name": "Maso",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "259_maso_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["259_maso_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "279",
    "set": "ASSE",
    "number": "260",
    "name": "Compulsive Accumulator",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "260_compulsive_accumulator_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["260_compulsive_accumulator_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "280",
    "set": "ASSE",
    "number": "261",
    "name": "Rank 2 Spellcaster",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "261_rank_2_spellcaster_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["261_rank_2_spellcaster_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "281",
    "set": "ASSE",
    "number": "262",
    "name": "Eye Of Destiny",
    "rarity": "Common",
    "rank": "2",
    "image": "262_eye_of_destiny_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["262_eye_of_destiny_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "282",
    "set": "ASSE",
    "number": "263",
    "name": "Skull Of Limbo",
    "rarity": "Common",
    "rank": "2",
    "image": "263_skull_of_limbo_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["263_skull_of_limbo_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "283",
    "set": "ASSE",
    "number": "264",
    "name": "Colossal Electric Blast",
    "rarity": "Rare",
    "rank": "2",
    "image": "264_colossal_electric_blast_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["264_colossal_electric_blast_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "284",
    "set": "ASSE",
    "number": "265",
    "name": "Orb Of Pain",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "265_orb_of_pain_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["265_orb_of_pain_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "285",
    "set": "ASSE",
    "number": "266",
    "name": "Weight Of Soul",
    "rarity": "Common",
    "rank": "2",
    "image": "266_weight_of_soul_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["266_weight_of_soul_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "286",
    "set": "ASSE",
    "number": "267",
    "name": "Alchemist",
    "rarity": "Rare",
    "rank": "2",
    "image": "267_alchemist_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["267_alchemist_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "287",
    "set": "ASSE",
    "number": "268",
    "name": "Piracy",
    "rarity": "Rare",
    "rank": "2",
    "image": "268_piracy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["268_piracy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "288",
    "set": "ASSE",
    "number": "269",
    "name": "Counterfeit",
    "rarity": "Rare",
    "rank": "2",
    "image": "269_counterfeit_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["269_counterfeit_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "289",
    "set": "ASSE",
    "number": "270",
    "name": "Focus",
    "rarity": "Rare",
    "rank": "2",
    "image": "270_focus_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["270_focus_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "290",
    "set": "ASSE",
    "number": "271",
    "name": "The Pit",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "271_the_pit_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["271_the_pit_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "291",
    "set": "ASSE",
    "number": "272",
    "name": "Four Arms",
    "rarity": "Legendary",
    "rank": "2",
    "image": "272_four_arms_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["272_four_arms_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "292",
    "set": "ASSE",
    "number": "273",
    "name": "Ash Clover",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "273_ash_clover_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["273_ash_clover_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "293",
    "set": "ASSE",
    "number": "274",
    "name": "Aureola",
    "rarity": "Uncommon",
    "rank": "2",
    "image": "274_aureola_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["274_aureola_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "294",
    "set": "ASSE",
    "number": "275",
    "name": "Monster Heart",
    "rarity": "Rare",
    "rank": "2",
    "image": "275_monster_heart_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["275_monster_heart_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "295",
    "set": "ASSE",
    "number": "276",
    "name": "Bomb Generator",
    "rarity": "Common",
    "rank": "2",
    "image": "276_bomb_generator_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["276_bomb_generator_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "296",
    "set": "ASSE",
    "number": "277",
    "name": "Z Training",
    "rarity": "Rare",
    "rank": "3",
    "image": "277_z_training_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["277_z_training_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "297",
    "set": "ASSE",
    "number": "278",
    "name": "Unswerving",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "278_unswerving_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["278_unswerving_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "298",
    "set": "ASSE",
    "number": "279",
    "name": "Rising",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "279_rising_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["279_rising_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "299",
    "set": "ASSE",
    "number": "280",
    "name": "Clone Training",
    "rarity": "Rare",
    "rank": "3",
    "image": "280_clone_training_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["280_clone_training_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "300",
    "set": "ASSE",
    "number": "281",
    "name": "The Reaper",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "281_the_reaper_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["281_the_reaper_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "301",
    "set": "ASSE",
    "number": "282",
    "name": "Oxo The Cat Owner",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "282_oxo_the_cat_owner_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["282_oxo_the_cat_owner_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "302",
    "set": "ASSE",
    "number": "283",
    "name": "Last Training",
    "rarity": "Rare",
    "rank": "3",
    "image": "283_last_training_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["283_last_training_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "303",
    "set": "ASSE",
    "number": "284",
    "name": "Money Training",
    "rarity": "Rare",
    "rank": "3",
    "image": "284_money_training_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["284_money_training_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "304",
    "set": "ASSE",
    "number": "285",
    "name": "Irresistible Accumulation",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "285_irresistible_accumulation_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["285_irresistible_accumulation_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "305",
    "set": "ASSE",
    "number": "286",
    "name": "Rank 3 Spellcaster",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "286_rank_3_spellcaster_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["286_rank_3_spellcaster_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "306",
    "set": "ASSE",
    "number": "287",
    "name": "Bloody Coin",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "287_bloody_coin_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["287_bloody_coin_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "307",
    "set": "ASSE",
    "number": "288",
    "name": "Dark Thoughts",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "288_dark_thoughts_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["288_dark_thoughts_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "308",
    "set": "ASSE",
    "number": "289",
    "name": "Peanuts",
    "rarity": "Common",
    "rank": "3",
    "image": "289_peanuts_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["289_peanuts_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "309",
    "set": "ASSE",
    "number": "290",
    "name": "Lollipop",
    "rarity": "Common",
    "rank": "3",
    "image": "290_lollipop_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["290_lollipop_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "310",
    "set": "ASSE",
    "number": "291",
    "name": "Mushroom",
    "rarity": "Common",
    "rank": "3",
    "image": "291_mushroom_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["291_mushroom_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "311",
    "set": "ASSE",
    "number": "292",
    "name": "Burger Time",
    "rarity": "Common",
    "rank": "3",
    "image": "292_burger_time_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["292_burger_time_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "312",
    "set": "ASSE",
    "number": "293",
    "name": "Cake",
    "rarity": "Common",
    "rank": "3",
    "image": "293_cake_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["293_cake_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "313",
    "set": "ASSE",
    "number": "294",
    "name": "Red Hot Chili Pepper",
    "rarity": "Common",
    "rank": "3",
    "image": "294_red_hot_chili_pepper_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["294_red_hot_chili_pepper_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "314",
    "set": "ASSE",
    "number": "295",
    "name": "Golden Fork",
    "rarity": "Common",
    "rank": "3",
    "image": "295_golden_fork_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["295_golden_fork_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "315",
    "set": "ASSE",
    "number": "296",
    "name": "Magic Seal",
    "rarity": "Uncommon",
    "rank": "3",
    "image": "296_magic_seal_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["296_magic_seal_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "316",
    "set": "ASSE",
    "number": "297",
    "name": "Allergy",
    "rarity": "Uncommon",
    "rank": "4",
    "image": "297_allergy_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["297_allergy_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "317",
    "set": "ASSE",
    "number": "298",
    "name": "At Your Service",
    "rarity": "Uncommon",
    "rank": "4",
    "image": "298_at_your_service_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["298_at_your_service_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "318",
    "set": "ASSE",
    "number": "299",
    "name": "Rank 4 Spellcaster",
    "rarity": "Common",
    "rank": "4",
    "image": "299_rank_4_spellcaster_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Imminent",
    "meta": cardsText["299_rank_4_spellcaster_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "319",
    "set": "ASSE",
    "number": "300",
    "name": "Hungry Like The Wolf",
    "rarity": "Common",
    "rank": "4",
    "image": "300_hungry_like_the_wolf_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["300_hungry_like_the_wolf_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "320",
    "set": "ASSE",
    "number": "301",
    "name": "Bomb",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "301_bomb_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Incantation",
    "meta": cardsText["301_bomb_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "321",
    "set": "ASSE",
    "number": "302",
    "name": "Pristine Level Up",
    "rarity": "Legendary",
    "rank": "1",
    "image": "302_pristine_level_up_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Level",
    "meta": cardsText["302_pristine_level_up_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "322",
    "set": "ASSE",
    "number": "303",
    "name": "Healthy Level Up",
    "rarity": "Rare",
    "rank": "2",
    "image": "303_healthy_level_up_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Level",
    "meta": cardsText["303_healthy_level_up_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "323",
    "set": "ASSE",
    "number": "304",
    "name": "Generous Level Up",
    "rarity": "Rare",
    "rank": "3",
    "image": "304_generous_level_up_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Level",
    "meta": cardsText["304_generous_level_up_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "324",
    "set": "ASSE",
    "number": "305",
    "name": "Efficient Level Up",
    "rarity": "Rare",
    "rank": "4",
    "image": "305_efficient_level_up_EN.png",
    "wesire": ['card', 'asse'],
    "type": "Level",
    "meta": cardsText["305_efficient_level_up_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "325",
    "set": "ASSE",
    "number": "306",
    "name": "Core Level Up",
    "rarity": "Common",
    "rank": "unlimited",
    "image": "306_core_level_up_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["306_core_level_up_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "326",
    "set": "ASSE",
    "number": "307",
    "name": "Juan",
    "rarity": "Rare",
    "rank": "1",
    "image": "307_juan_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["307_juan_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "327",
    "set": "ASSE",
    "number": "307",
    "name": "Juan (Alt)",
    "rarity": "Rare",
    "rank": "1",
    "image": "307_juanV2_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["307_juanV2_EN"]?.text,
  },
  {
    "hash": "XXXXXXXXX",
    "id": "328",
    "set": "ASSE",
    "number": "308",
    "name": "El Pollo",
    "rarity": "Rare",
    "rank": "unlimited",
    "image": "308_el_pollo_EN.png",
    "wesire": ['card', 'asse'],
    "type": "",
    "meta": cardsText["308_el_pollo_EN"]?.text,
  }
];

export { cardsData };


const decksData: Array<DeckData> = [{
  "hash": "XXXX",
  "id": '1',
  "name": "A normal name",
  "meta": "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.",
  "list": [1,2,4,9,53,47,47,47,35,85,96,78,78,45,45,45,32,150,150,69,69,13,131,42,42,318,306,306,306,306,299,299,299,285,288,285,288,201,201,201,48],
},{
  "hash": "XXXX",
  "id": '2',
  "name": "A verrrrrrrrrry wary wacky l0000ng nameeeee !",
  "meta": "Test deck",
  "list": [1,2,16,5,53,47,47,47,35,85,96,78,78,45,45,45,32,150,150,69,69,13,131,42,42,318,306,306,306,306,299,299,299,285,288,285,288,201,201,201,48],
},{
  "hash": "XXXX",
  "id": '3',
  "name": "Some kind of name, aka name",
  "meta": "Test deck",
  "list": [1,2,23,18,53,47,47,47,35,85,96,78,78,45,45,45,32,150,150,69,69,13,131,42,42,318,306,306,306,306,299,299,299,285,288,285,288,201,201,201,48],
},{
  "hash": "XXXX",
  "id": '4',
  "name": "TEST",
  "meta": "Test deck",
  "list": [1,2,7,3,53,47,47,47,35,85,96,78,78,45,45,45,32,150,150,69,69,13,131,42,42,318,306,306,306,306,299,299,299,285,288,285,288,201,201,201,48],
},{
  "hash": "XXXX",
  "id": '5',
  "name": "TEST",
  "meta": "Test deck",
  "list": [1,2,14,9,53,47,47,47,35,85,96,78,78,45,45,45,32,150,150,69,69,13,131,42,42,318,306,306,306,306,299,299,299,285,288,285,288,201,201,201,48],
},{
  "hash": "XXXX",
  "id": '6',
  "name": "TEST",
  "meta": "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.",
  "list": [1,2,8,20,53,47,47,47,35,85,96,78,78,45,45,45,32,150,150,69,69,13,131,42,42,318,306,306,306,306,299,299,299,285,288,285,288,201,201,201,48],
},{
  "hash": "XXXX",
  "id": '7',
  "name": "A middly long name",
  "meta": "Test deck",
  "list": [1,2,7,12,53,47,47,47,35,85,96,78,78,45,45,45,32,150,150,69,69,13,131,42,42,318,306,306,306,306,299,299,299,285,288,285,288,201,201,201,48],
},{
  "hash": "XXXX",
  "id": '8',
  "name": "A middly long name",
  "meta": "Test deck",
  "list": [1,2,7,12,53,47,47,47,35,85,96,78,78,45,45,45,32,150,150,69,69,13,131,42,42,318,306,306,306,306,299,299,299,285,288,285,288,201,201,201,48],
},{
  "hash": "XXXX",
  "id": '9',
  "name": "A middly long name",
  "meta": "Test deck",
  "list": [1,2,7,12,53,47,47,47,35,85,96,78,78,45,45,45,32,150,150,69,69,13,131,42,42,318,306,306,306,306,299,299,299,285,288,285,288,201,201,201,48],
},{
  "hash": "XXXX",
  "id": '10',
  "name": "A middly long name",
  "meta": "Test deck",
  "list": [1,2,7,12,53,47,47,47,35,85,96,78,78,45,45,45,32,150,150,69,69,13,131,42,42,318,306,306,306,306,299,299,299,285,288,285,288,201,201,201,48],
}]

export { decksData };
