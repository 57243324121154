import Logout, { handleLogout } from '../Logout';
import { HomeOutlined, ExperimentOutlined, LeftOutlined, RightOutlined, UserOutlined, MenuOutlined, AppstoreOutlined, LogoutOutlined } from '@ant-design/icons';
import { Menu, Space, Button, Typography, FloatButton } from 'antd';
import Search from 'antd/lib/input/Search';
import Title from 'antd/lib/typography/Title';

interface itemProps {
  label: string | Element | any;
  key: string;
  icon?: any;
  children?: Array<any>;
}

const Header = ({
  cardsCount,
  handleEdit,
  isAdding,
  isBuilding,
  isCollapsed,
  isDashboard,
  isEditing,
  isMenuOpen,
  isXS = false,
  selectedCard,
  setInputValue,
  setIsAdding,
  setIsAuthenticated,
  setIsBuilding,
  setIsCollapsed,
  setIsDashboard,
  setIsEditing,
  setIsMenuOpen,
  setSelectedCard,
  setIsDeckEditing,
}) => {
  const items: Array<itemProps> = [
    {
      label: 'Home',
      key: 'home',
      icon: <HomeOutlined />,
    },
    {
      label: 'Decks',
      key: 'decks',
      icon: <ExperimentOutlined />,
      children: [
        {
          label: 'Create a new one',
          key: 'decks:create',
        },
        {
          label: 'Edit one',
          key: 'decks:edit',
        },
        {
          label: 'Manage',
          key: 'decks:manage',
        },
      ],
    },
    {
      label: 'User',
      key: 'user',
      icon: <UserOutlined />,
      children: [
        {
          label: 'Update profile',
          key: 'user:edit',
        },
        {
          label: (<Logout setIsAuthenticated={setIsAuthenticated} />),
          key: 'user:logoutt',
        },
      ],
    },
    {
      label: (
        isDashboard ?
          <Search placeholder="Input search text" allowClear onSearch={(value: string) => setInputValue(value)} style={{ width: 400 }} /> :
          isEditing ?
            <Space style={{ width: 400, display: "flex", justifyContent: 'space-between', listStyleType: 'none' }} >
              <Button shape="round" size='small' icon={<LeftOutlined />} onClick={() => handleEdit((parseInt(selectedCard.id) - (parseInt(selectedCard.id) > 1 ? 1 : 0)).toString())} />
              <Typography className='LabradaSemiBold'>{selectedCard.name}</Typography>
              <Button shape="round" icon={<RightOutlined />} size='small' onClick={() => handleEdit((parseInt(selectedCard.id) + (parseInt(selectedCard.id) < parseInt(cardsCount) ? 1 : 0)).toString())} />
            </Space> :
            null
      ),
      key: 'actions',
    }
  ];

  const onClick = (e: any) => {
    switch (e.key) {
      case 'search':
        if (!isDashboard) {
          setIsDashboard(true)
          setIsEditing(false)
          setIsAdding(false)
          setIsMenuOpen(false)
          setIsDeckEditing(false)
        }
        break;
      case 'home':
        setIsCollapsed(true)
        setSelectedCard(null)
        setInputValue('')
        setIsMenuOpen(false)
        setIsDeckEditing(false)
        if (!isDashboard) {
          setIsDashboard(true)
          setIsBuilding(false)
          setIsEditing(false)
          setIsAdding(false)
        }
        break;
      case 'decks':
        setIsBuilding(true)
        setIsCollapsed(true)
        setIsDashboard(false)
        setIsEditing(false)
        setIsAdding(false)        
        setIsDeckEditing(false)
        setInputValue('')
        setSelectedCard(null)
        setIsMenuOpen(false)
        break;
      case 'user':
        handleLogout(setIsAuthenticated)
        setIsMenuOpen(false)
        break;
      default:
        break;
    }
  };

  const floatButtonStyle = isXS ?
    {
      borderRadius: '.7em',
      left: 'calc(.5em + 3px)',
      top: 2,
      background: '#fff0',
      display: 'flex',
      flexFlow: 'column-reverse',
      insetBlockEnd: 'unset',
      width: '18px',
    } :
    {
      display: 'none'
    };

  return (
    <Menu
      className="glassytopmenu"
      onClick={onClick}
      selectedKeys={['current']}
      mode="horizontal"
      inlineCollapsed={false}
      style={{
        position: 'sticky',
        top: '0',
        zIndex: 1
      }}
    >
      {!isXS ?
        <>
          <Menu.Item
            key={items[0].key}
            icon={false}
            title={false}
          >
            <Title
              className='AbstractGroovyMobileMenuLogo'
              style={{
                fontSize: '38px',
                lineHeight: '32px',
                textAlign: 'center',
                width: 'auto',
                margin: 0,
                padding: 0,
              }}
            >
              Dexkise
            </Title>
          </Menu.Item>
          <Menu.Item
            key={items[1].key}
            icon={items[1].icon}
            title={items[1].label}
          />
          <Menu.Item
            key={items[2].key}
            icon={items[2].icon}
            title={items[2].label}
          />
          <Menu.Item>
            {isDashboard ?
              null :
              isEditing ?
                <Space style={{ width: 400, display: "flex", justifyContent: 'space-between', listStyleType: 'none' }} >
                  <Button shape="round" size='small' icon={<LeftOutlined />} onClick={() => handleEdit((parseInt(selectedCard.id) - (parseInt(selectedCard.id) > 1 ? 1 : 0)).toString())} />
                  <Typography className='LabradaSemiBold'>{selectedCard.name}</Typography>
                  <Button shape="round" icon={<RightOutlined />} size='small' onClick={() => handleEdit((parseInt(selectedCard.id) + (parseInt(selectedCard.id) < parseInt(cardsCount) ? 1 : 0)).toString())} />
                </Space> :
                null}
          </Menu.Item>
        </> :
        <Menu.Item
          key={null}
          icon={null}
          title={null}
          style={{ cursor: 'default' }}
          disabled
          id='monoButtonMenu'
        >
          <FloatButton.Group
            className={'floatButtonMenu'}
            rootClassName='floatButtonRoot'
            open={isMenuOpen}
            trigger="click"
            style={floatButtonStyle}
            icon={<MenuOutlined />}
            shape="square"
            onClick={(e) => {
              setIsMenuOpen(!isMenuOpen)
            }}
          >
            <FloatButton
              tooltip={'Card list'}
              onClick={() => {
                onClick({ key: 'home' })
              }}
              icon={<AppstoreOutlined />}
            />
            <FloatButton
              tooltip={'Decks'}
              onClick={() => {
                onClick({ key: 'decks' })
              }}
              icon={ <ExperimentOutlined />}
            />
            <FloatButton
              tooltip={'Logout'}
              icon={<LogoutOutlined />}
              onClick={() => handleLogout(setIsAuthenticated)}
            />
          </FloatButton.Group>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              paddingLeft: !isCollapsed && !isEditing ? '58px' : 'unset',
              width: isCollapsed || isEditing ? '100vw' : 'calc(100vw - 320px)',
              fontSize: '38px',
              lineHeight: '32px',
              textAlign: 'center',
            }}
          >
            <Title
              className='AbstractGroovyMobileMenuLogo'
              style={{
                fontSize: '42px',
                textAlign: 'center',
                marginBottom: '0',
              }}
              onClick={() => {onClick({ key: 'home' })}}
            >
              Dexkise
            </Title>
          </div>
        </Menu.Item>}
    </Menu>
  );
};

export default Header;
