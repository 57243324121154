import React from "react";
import { List, Row, Col, Button, Typography } from "antd";
import CardItem from "./CardItem";
import { CardData } from "../../type";
import { Document } from "flexsearch";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const DeckCardsList = ({ cards, handleEdit, setSelectedCard, isCollapsed = true, inputValue = '', isXS = false, filteredList, setFilteredList, setIsMenuOpen }) => {
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [results, setResults] = React.useState<Array<String> | any>([]);

  const [grid, setGrid] = React.useState({
    gutter: 6,
    xs: 1,
    sm: 3,
    md: 4,
    lg: 5,
    xl: 6,
    xxl: 6,
  });

  const [index, setIndex] = React.useState(new Document({
    tokenize: "strict",
    optimize: true,
    resolution: 9,
    document: {
      id: "id",
      index: [{
        field: "name",
        tokenize: "forward",
        resolution: 5
      }, {
        field: "meta",
        tokenize: "forward",
        resolution: 5
      }, {
        field: "rarity",
        tokenize: "forward",
        resolution: 5
      }]
    }
  }));


  React.useEffect(() => {
    Object.values(cards as Array<CardData>).forEach((item) => {
      setIndex(index.add({ id: item.id, name: item.name, meta: item.meta, rarity: item.rarity }));
    });
  }, [cards, index, isCollapsed]);

  React.useEffect(() => {
      setGrid({
        gutter: 6,
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 5,
        xxl: 6,
      })
  }, [isCollapsed]);

  React.useEffect(() => {
    setLoading(true)
    setResults(index.search(inputValue));
    setLoading(false)
  }, [index, inputValue]);
  
  React.useEffect(() => {
    if (inputValue && inputValue.length > 0) {
      setIsMenuOpen(false)
      const resultMapped = results.reduce((accumulator, currentValue) => {
        return [...new Set([...accumulator, ...currentValue.result])]
      }, []);
      setFilteredList(resultMapped.map(id => (cards as Array<CardData>).find(card => card.id === id)))
    } else {
      setFilteredList(cards)
    }
  }, [cards, inputValue, results, setFilteredList, setIsMenuOpen]);

  return (
    <Row>
      <Col
        span={24}
        style={{ maxWidth: '100vw' }}
      >
        <List
          loading={loading}
          grid={grid}
          style={{ paddingTop: '.2em', margin: '0px' }}
          className="cardlist"
          dataSource={filteredList}
          pagination={{
            size: !isXS ? 'small' : 'small',
            total: filteredList.length,
            pageSize: 44,
            pageSizeOptions: [44],
            showLessItems: true,
            showSizeChanger: false,
            onChange: (page, pageSize) => {
              setCurrentPage(page)
            },
            itemRender: (page, type: 'page' | 'prev' | 'next', originalElement)=>{
              if (type === 'prev') {
                return <Button className='glassybutton' size={isXS ? "small" : "middle"}><LeftOutlined />{!isXS ? `Previous` : null}</Button>;
              }
              if (type === 'next') {
                return <Button className='glassybutton' size={isXS ? "small" : "middle"}>{!isXS ? `Next 44` : null}<RightOutlined /></Button>;
              }
              if (!isXS && !isCollapsed && page === currentPage) {
                return <Typography style={{fontWeight: 'lighter', paddingTop: '6px'}}>{`Page ${page} / ${Math.ceil(filteredList.length / 44)}`}</Typography>;
              } 
              if (!isXS && isCollapsed && page === currentPage) {
                return <Typography style={{fontWeight: 'lighter', paddingTop: '6px'}}>{`Page ${page} / ${Math.ceil(filteredList.length / 44)} - Total ${filteredList.length} cards`}</Typography>;
              } 
              if (isXS && isCollapsed && page === currentPage) {
                return <Typography style={{fontWeight: 'lighter', paddingTop: '6px'}}>{`${page}`}</Typography>;
              } 
            },
            style: {
              marginRight: !isXS ? '16px' : '0px',
              marginLeft: !isXS ? '16px' : '0px',
              marginBottom: !isXS ? '24px' : '16px',
              marginTop: !isXS ? '16px' : '8px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              textAlign: 'center',
            },
            position: 'both',
            showTitle: false,
            responsive: true,
            hideOnSinglePage: true,
          }}
          renderItem={item => <CardItem
            item={item}
            handleEdit={handleEdit}
            setSelectedCard={setSelectedCard}
            isInList={true}
            isXS={isXS}
            padding={"0"}
            setIsMenuOpen={setIsMenuOpen}
          />}
        />
      </Col>
    </Row>
  );
};

export default DeckCardsList;