import React, { useState } from "react";

const useXSCheck = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(typeof window.navigator === 'undefined' ? false : navigator.userAgent.match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i) != null);
  
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setIsMobile(typeof window.navigator === 'undefined' ? false : navigator.userAgent.match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i) != null);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (width <= 700 || isMobile);
}

export default useXSCheck;