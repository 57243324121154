import React from "react";
import { Col, Image, Button, Space, Descriptions, DescriptionsProps, Typography } from "antd";
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import CardItem from "./CardItem";
import useSwipe from "../../utils/useSwipe";

const SiderContent = ({ handleEdit, selectedCard, setSelectedCard, setIsCollapsed, setIsMenuOpen }) => {
  const [isPreviewVisible, setIsPreviewVisible] = React.useState(false);
  const [scaleStep, setScaleStep] = React.useState(1);

  const swipeHandlers = useSwipe({ 
    onSwipedLeft: () => null, 
    onSwipedRight: () => {
      if (!isPreviewVisible) {
        setSelectedCard(null)
        setIsCollapsed(true)
      }
    } 
  });

  React.useEffect(() => {
    setScaleStep(1)
  }, [])

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Type',
      children: selectedCard?.type,
    },
    {
      key: '2',
      label: 'Rarity',
      children: selectedCard?.rarity,
    },
    {
      key: '3',
      label: 'Rank',
      children: selectedCard?.rank,
    },
    {
      key: '4',
      children: <Typography.Paragraph
          ellipsis={false ? { rows: 4 } : false}
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-line',
            overflowY: 'scroll',
            margin: '0px',
            width: '100%',
            fontSize: '.9em',
            overflowX: 'hidden',
          }}>
          {selectedCard?.meta}
        </Typography.Paragraph>,
    },
  ];

  return (
    <div {...swipeHandlers}>
    <Col
      span={24}
      style={{ height: "100%" }}
      flex={'auto'}
    >
      <Col>
        {selectedCard ? (<div className="selectedcard">
            <CardItem
              item={selectedCard}
              handleEdit={handleEdit}
              setSelectedCard={setSelectedCard}
              padding=".7em"
              is3D={false}
              setIsMenuOpen={setIsMenuOpen}
            />
          </div>) :
          null
        }
      </Col>
      <Col style={{ height: 'calc(100% - 440px)' }}>
        {selectedCard ? (<Space style={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
          <Descriptions
            size={'small'}
            column={1}
            style={{ padding: '0 .5em 0 .5em', bottom: 0, display: 'flex', flexDirection: 'column' }}
            layout={'horizontal'}
            title={selectedCard.name}
            items={items}
            labelStyle={{}}
            extra={[
              <Button onClick={() => setIsPreviewVisible(true)} size="small"><SearchOutlined /></Button>,
              <Button onClick={() => handleEdit(selectedCard.id)} size="small"><EditOutlined /></Button>,
            ]}
          />
        </Space>) :
          null
        }
        {selectedCard ? (
          <Image
            style={{ display: 'none' }}
            src={('/img/' + selectedCard.image).replace('.png','.webp')}
            preview={{
              visible: isPreviewVisible,
              destroyOnClose: true,
              scaleStep,
              minScale: .5,
              maxScale: 3,
              movable: true,
              height: '90%',
              width: 'auto',
              src: ('/img/' + selectedCard.image).replace('.png','.webp'),
              onVisibleChange: (value) => {
                setIsPreviewVisible(value);
              },
            }}
          />) :
          null
        }
      </Col>
    </Col>
    </div>
  );
};

export default SiderContent;