import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import Swal from 'sweetalert2';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import useXSCheck from '../../utils/useXSCheck';

const Login = ({ setIsAuthenticated }) => {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  const [email, setEmail] = React.useState('test@test.com');
  const [password, setPassword] = React.useState('nbvcxw');
  const isMobile = useXSCheck();

  const handleLogin = e => {
    e.preventDefault();

    if (true && email && password) {
      Swal.fire({
        timer: 1500,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          localStorage.setItem('is_authenticated', JSON.stringify(true));
          setIsAuthenticated(true);

          Swal.fire({
            icon: 'success',
            title: 'Successfully logged in!',
            showConfirmButton: false,
            timer: 1500,
          });
        },
      });
    } else {
      Swal.fire({
        timer: 1500,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Incorrect email or password.',
            showConfirmButton: true,
          });
        },
      });
    }
  };


  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Title 
          className='AbstractGroovy' 
          color='linear-gradient(28deg, #0d1962fc 0%,#0d1962e6 18%,#03ff7f 26%,#01edb3e6 34%,#00e3d0 38%,#0b7e80 44%,#033dfff2 49%,#bf05e1e6 58%,#9f0f60e6 68%, #261762 82%, #0d1962fc 100%)'
          style={{ 
            fontSize: isMobile ? '6em' : '10em',
            textAlign: 'center',
            padding: '8px',
          }}
        >
          Dexkise
        </Title>
        <Form
          name="basic"
          title='Dexkise'
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 420 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder='Username' onChange={e => setEmail(e.target.value)} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" prefix={<LockOutlined className="site-form-item-icon" />} onChange={e => setPassword(e.target.value)} />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24, flex: 'auto' }}>
            <Button className='glassybutton' style={{ width: '100%' }} type="default" htmlType="submit" onClick={handleLogin}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Login;
