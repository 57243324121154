import Swal from 'sweetalert2';

export const handleLogout = (setIsAuthenticated) => {
  Swal.fire({
    icon: 'question',
    title: 'Logging Out',
    text: 'Are you sure you want to log out?',
    showCancelButton: true,
    confirmButtonText: 'Yes',
  }).then(result => {
    if (result.value) {
      Swal.fire({
        timer: 1500,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          localStorage.setItem('is_authenticated', JSON.stringify(false));
          setIsAuthenticated(false);
        },
      });
    }
  });
};

const Logout = ({ setIsAuthenticated, children = null }) => {

  return (children ? 
    children : 
    <span style={{width: '100%', height: '100%'}} onClick={() => handleLogout(setIsAuthenticated)}>
      Logout
    </span>
  );
};

export default Logout;
