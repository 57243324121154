import { Row, Col, Form, Input, Button, Card } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import CardItem from './CardItem';
/* 
function downloadObjectAsJson(exportObj, exportName) {
  const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
} */

const Edit = ({
  cards,
  selectedCard,
  setCards,
  setIsEditing,
  setIsDashboard,
  setIsAdding,
  isXS,
  setIsMenuOpen,
  openNotificationWithIcon,
}) => {
  const id = selectedCard.id;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({...selectedCard})
    setHash(selectedCard.hash)
    setSet(selectedCard.set)
    setNumber(selectedCard.number)
    setName(selectedCard.name)
    setMeta(selectedCard.meta)
    setRarity(selectedCard.rarity)
    setRank(selectedCard.rank)
    setImage(selectedCard.image)
    setWesire(selectedCard.wesire)
    setType(selectedCard.type)
  }, [form, selectedCard])

  const [hash, setHash] = useState(selectedCard.hash);
  const [set, setSet] = useState(selectedCard.set);
  const [number, setNumber] = useState(selectedCard.number);
  const [name, setName] = useState(selectedCard.name);
  const [meta, setMeta] = useState(selectedCard.meta);
  const [rarity, setRarity] = useState(selectedCard.rarity);
  const [rank, setRank] = useState(selectedCard.rank);
  const [image, setImage] = useState(selectedCard.image);
  const [wesire, setWesire] = useState(selectedCard.wesire);
  const [type, setType] = useState(selectedCard.type);

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!id ||
      !meta ||
      !set ||
      !number ||
      !name ||
      !rarity ||
      !rank ||
      !image ||
      !wesire ||
      !type) {
      openNotificationWithIcon('error:edit')
      return null;
    }

    const card = {
      hash,
      id,
      set,
      number,
      name,
      rarity,
      rank,
      image,
      wesire,
      type,
      meta,
    };

    for (let i = 0; i < cards.length; i++) {
      if (cards[i].id === id) {
        cards.splice(i, 1, card);
        break;
      }
    }

    localStorage.setItem('cards_data', JSON.stringify(cards));
    //downloadObjectAsJson(cards, 'cards_data')
    setCards(cards);
    setIsEditing(false);
    openNotificationWithIcon('success:edit')
  };

  return (
    <Row style={{ minHeight: 'calc(100vh - 46px)' }}>
      <Form
        name="editcard"
        form={form}
        size={isXS ? 'small' : 'middle'}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        initialValues={{ ...selectedCard }}
        autoComplete="off"
      >
        <Row style={{ minWidth: '100vw' }} justify={'space-around'}>
          <Col flex={"auto"} md={16} lg={8}>
            <Card className='glassiphy' style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}>
              <Form.Item
                label="hash"
                name="hash"
                rules={[{ required: true, message: 'Please input your hash!' }]}
              >
                <Input onChange={e => setHash(e.target.value)} />
              </Form.Item>

              <Form.Item
                label="id"
                name="id"
                rules={[{ required: true, message: 'Please input your id!' }]}
              >
                <Input disabled={true} onChange={e => null} />
              </Form.Item>

              <Form.Item
                label="set"
                name="set"
                rules={[{ required: true, message: 'Please input your set!' }]}
              >
                <Input onChange={e => setSet(e.target.value)} />
              </Form.Item>

              <Form.Item
                label="number"
                name="number"
                rules={[{ required: true, message: 'Please input your number!' }]}
              >
                <Input onChange={e => setNumber(e.target.value)} />
              </Form.Item>

              <Form.Item
                label="name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input onChange={e => setName(e.target.value)} />
              </Form.Item>

              <Form.Item
                label="rarity"
                name="rarity"
                rules={[{ required: true, message: 'Please input your rarity!' }]}
              >
                <Input onChange={e => setRarity(e.target.value)} />
              </Form.Item>

              <Form.Item
                label="rank"
                name="rank"
                rules={[{ required: true, message: 'Please input your rank!' }]}
              >
                <Input onChange={e => setRank(e.target.value)} />
              </Form.Item>

              <Form.Item
                label="wesire"
                name="wesire"
                rules={[{ required: true, message: 'Please input your wesire!' }]}
              >
                <Input onChange={e => setWesire(e.target.value)} />
              </Form.Item>

              <Form.Item
                label="type"
                name="type"
                rules={[{ required: true, message: 'Please input your type!' }]}
              >
                <Input onChange={e => setType(e.target.value)} />
              </Form.Item>

              <Form.Item
                label="image"
                name="image"
                rules={[{ required: true, message: 'Please input your image!' }]}
              >
                <Input onChange={e => setImage(e.target.value)} />
              </Form.Item>
            </Card>
          </Col>
          <Col flex={"auto"} md={8} lg={8}>
            <div className="selectedcard">
              <CardItem
                item={selectedCard}
                isActionsDisabled={true}
                setIsMenuOpen={setIsMenuOpen}
              />
            </div>
          </Col>
          <Col flex={"auto"} md={24} lg={8}>
            <Card className='glassiphy' style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
              <Form.Item
                label="meta"
                name="meta"
                rules={[{ required: true, message: 'Please input your meta!' }]}
              >
                <TextArea
                  onChange={e => setMeta(e.target.value)}
                  placeholder="Controlled autosize"
                  autoSize={{ minRows: 24, maxRows: 24 }}
                />
              </Form.Item>
            </Card>
          </Col>
        </Row>
        <Row style={{ minWidth: '100vw', padding: '1em' }} justify={"center"}>
          <Button type="primary" htmlType="submit" onClick={handleUpdate}>
            Submit
          </Button>
          <Button htmlType="button" onClick={() => {
            setIsEditing(false)
            setIsDashboard(true)
            setIsAdding(false)
          }}>
            Cancel
          </Button>
        </Row>
      </Form>
    </Row>
  );
};

export default Edit;
