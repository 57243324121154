import React, { useState } from 'react';
import Swal from 'sweetalert2';

const Add = ({ cards, setCards, setIsAdding }) => {

  const autoId = cards.length + 1;
  const [hash, setHash] = useState('');
  const [id, setId] = useState(autoId);
  const [set, setSet] = useState('');
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [rarity, setRarity] = useState('');
  const [rank, setRank] = useState('');
  const [image, setImage] = useState('');
  const [wesire, setWesire] = useState('');
  const [type, setType] = useState('');

  const handleAdd = e => {
    e.preventDefault();

    if (!hash ||
      !set ||
      !number ||
      !name ||
      !rarity ||
      !rank ||
      !image ||
      !wesire ||
      !type) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'All fields are required.',
        showConfirmButton: true,
      });
    }

    const newCard = {
      hash,
      id,
      set,
      number,
      name,
      rarity,
      rank,
      image,
      wesire,
      type,
    };

    cards.push(newCard);
    localStorage.setItem('cards_data', JSON.stringify(cards));
    setCards(cards);
    setIsAdding(false);

    Swal.fire({
      icon: 'success',
      title: 'Added!',
      text: `${number} - ${name}'s data has been Added.`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <div className="small-container">
      <form onSubmit={handleAdd}>
        <h1>Add Card</h1>
        <div style={{ marginTop: '30px' }}>
          <input type="submit" value="Add" />
          <input
            style={{ marginLeft: '12px' }}
            className="muted-button"
            type="button"
            value="Cancel"
            onClick={() => setIsAdding(false)}
          />
        </div>
        <label htmlFor="hash">hash</label>
        <input
          id="hash"
          type="text"
          name="hash"
          value={hash}
          onChange={e => setHash(e.target.value)}
        />
        <label htmlFor="id">id</label>
        <input
          id="id"
          type="text"
          name="id"
          value={id}
          onChange={e => setId(e.target.value)}
        />
        <label htmlFor="set">set</label>
        <input
          id="set"
          type="text"
          name="set"
          value={set}
          onChange={e => setSet(e.target.value)}
        />
        <label htmlFor="number">number</label>
        <input
          id="number"
          type="number"
          name="number"
          value={number}
          onChange={e => setNumber(e.target.value)}
        />
        <label htmlFor="name">name</label>
        <input
          id="name"
          type="text"
          name="name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <label htmlFor="rarity">rarity</label>
        <input
          id="rarity"
          type="text"
          name="rarity"
          value={rarity}
          onChange={e => setRarity(e.target.value)}
        />
        <label htmlFor="rank">rank</label>
        <input
          id="rank"
          type="text"
          name="rank"
          value={rank}
          onChange={e => setRank(e.target.value)}
        />
        <label htmlFor="image">image</label>
        <input
          id="image"
          type="text"
          name="image"
          value={image}
          onChange={e => setImage(e.target.value)}
        />
        <label htmlFor="wesire">wesire</label>
        <input
          id="wesire"
          type="text"
          name="wesire"
          value={wesire}
          onChange={e => setWesire(e.target.value)}
        />
        <label htmlFor="type">type</label>
        <input
          id="type"
          type="text"
          name="type"
          value={type}
          onChange={e => setType(e.target.value)}
        />
        <div style={{ marginTop: '30px' }}>
          <input type="submit" value="Add" />
          <input
            style={{ marginLeft: '12px' }}
            className="muted-button"
            type="button"
            value="Cancel"
            onClick={() => setIsAdding(false)}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
