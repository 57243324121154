import * as CryptoJS from 'crypto-js';

const key = 'dexkise.deck';

export function encryptObj(msg) {  
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(msg), key);
  const transitmessage = encrypted.toString();
  return transitmessage;
}

export function decryptObj(encryptedBase64) {  
  const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key);
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}