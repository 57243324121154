import { useState, useEffect } from 'react';

import Login from '../Login';
import Dashboard from '../Dashboard';
import { ConfigProvider, notification, } from 'antd';
import useXSCheck from '../../utils/useXSCheck';
import { Helmet } from "react-helmet";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<any>(localStorage.getItem('is_authenticated') || null);
  const isXS = useXSCheck()
  useEffect(() => {
    setIsAuthenticated(JSON.parse(localStorage.getItem('is_authenticated') || null));
  }, []);

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type) => {
    switch (type) {
      case 'success:edit':
        api[type.split(':')[0]]({
          message: 'Update saved',
          description:
            'Updates were saved locally.',
        });
        break;
      case 'success:deckedit':
        api[type.split(':')[0]]({
          message: 'Update saved',
          description:
            'Updates were saved locally.',
        });
        break;
      case 'error:edit':
        api[type.split(':')[0]]({
          message: 'An error occured',
          description:
            'Please make sure each fields are filled.',
        });
        break;
      case 'error:deckedit':
        api[type.split(':')[0]]({
          message: 'An error occured',
          description:
            'Please make sure each fields are filled.',
        });
        break;
      default:
        break;
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgMask: "rgba(0, 0, 0, .75 )",
          colorBgContainerDisabled: "rgba(0, 0, 0, 0.15)",
          colorPrimary: '#096769',
        },
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dexkise</title>
        <meta name="description" content="Dexkise is some kind of Rise TCG deckbuilder" />
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0, maximum-scale=1.0;" />
      </Helmet>
      {contextHolder}
      {isAuthenticated ? (
        <Dashboard setIsAuthenticated={setIsAuthenticated} openNotificationWithIcon={openNotificationWithIcon} isXS={isXS} />
      ) : (
        <div style={{ overflow: 'hidden', height: '100vh', width: '100vw' }}>
          <Login setIsAuthenticated={setIsAuthenticated} />
        </div>
      )}
    </ConfigProvider>
  );
};

export default App;
