import { Col, Typography, Row } from "antd";

const FooterContent = () => (<>
  <Row style={{ display: 'flex', justifyContent: 'space-around', marginLeft: '21px', marginRight: '21px' }}>
    <Typography style={{ display: 'inline-block', marginBottom: '0' }}>
      <Typography.Paragraph style={{ marginBottom: '0', lineHeight: 1 }}>
        <Typography.Text style={{ fontSize: '12px' }} type='secondary'>
          The	literal	and	graphical	information	presented	on	this	site	about	Rise	TCG	(a	game	by	François	von	ORELLI),	including	card	images	and	symbols,	is	copyright	Dark	Screens	Games.
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph style={{ marginBottom: '0', lineHeight: 1 }}>
        <Typography.Text style={{ fontSize: '12px' }} type='secondary'>
          All	other	contents	©	2023	Dexkise.
        </Typography.Text>  
      </Typography.Paragraph>
      <Typography.Paragraph style={{ marginBottom: '0', lineHeight: 1 }}>
        <Typography.Text style={{ fontSize: '12px' }} type='secondary'>
          Dexkise	is	not	produced	by	or	endorsed	by	Dark	Screens	Games.
        </Typography.Text>
        <Typography.Text style={{ fontSize: '12px' }} type='secondary'>
          Dexkise	is	a	fan	made,	non	commercial,	non	cookied,	non	spying	and	freely	updated	site,	created	by&nbsp; 
        </Typography.Text>
        <Typography.Text style={{ fontSize: '12px' }} type='secondary'><a href='https://folio.tib0.com'>{` Tib0`}</a>.</Typography.Text>
      </Typography.Paragraph>
    </Typography>
  </Row>
  <Row style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '0', marginLeft: '42px', marginRight: '42px'  }}>
    <Col style={{ padding: '1em' }}><a href='https://www.darkscreengames.com'>darkscreengames.com</a></Col>
    <Col style={{ padding: '1em' }}><a href='https://discord.gg/Hvh3FJTb'>Discord : Rise TCG</a></Col>
    <Col style={{ padding: '1em' }}><a href='https://risecardgame.com/'>risecardgame.com</a></Col>
  </Row>
</>);

export default FooterContent;