import React, { useState, useEffect } from 'react';
import Header from './Header';
import Add from './Add';
import Edit from './Edit';
import { cardsData, decksData } from '../../data';
import { CardData, DeckData } from '../../type';
import { Drawer, FloatButton, Layout, Space } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import CardsList from './CardsList';
import SiderContent from './SiderContent';
import useMobileCheck from '../../utils/useXSCheck';
import FooterContent from './FooterContent';
import Search from 'antd/lib/input/Search';
import { SearchOutlined } from '@ant-design/icons';
import DeckList from './DeckList';
import DeckEdit from './DeckEdit';

const Dashboard = ({ setIsAuthenticated, openNotificationWithIcon, isXS }) => {
  const [cards, setCards] = useState<Array<CardData | null> | null>(cardsData);
  const [decks, setDecks] = useState<Array<DeckData | null> | null>(decksData);
  const [selectedCard, setSelectedCard] = useState<CardData | null>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDashboard, setIsDashboard] = useState(true);
  const [isBuilding, setIsBuilding] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filteredList, setFilteredList] = useState<Array<CardData>>(cards);
  const [selectedDeck, setSelectedDeck] = useState<DeckData | null>(null);
  const [isDeckEditing, setIsDeckEditing] = useState(false);

  useEffect(() => {
    const cardsDataTmp = JSON.parse(localStorage.getItem('cards_data') || null);
    if (cardsDataTmp !== '' && cardsDataTmp !== null && Object.keys(cardsDataTmp).length !== 0) setCards(cardsDataTmp);
    const decksDataTmp = JSON.parse(localStorage.getItem('decks_data') || null);
    if (decksDataTmp !== '' && decksDataTmp !== null && Object.keys(decksDataTmp).length !== 0) setDecks(decksDataTmp);
  }, []);
  useEffect(() => {
    if (selectedCard) setIsCollapsed(false)
    if (!isCollapsed && document.body.querySelector('.ant-drawer-body')) {
      document.body
        .querySelector('.ant-drawer-body')
        .scrollTo(0, 0);
    }
  }, [isCollapsed, selectedCard]);

  const handleEdit = id => {
    if (!cards || cards.length === 0) return;
    const [card]: Array<CardData | null> | undefined = cards.filter(card => card?.id === id);
    if (!card) return;

    setSelectedCard(card);
    setIsDashboard(false);
    setIsBuilding(false);
    setIsCollapsed(true);
    setIsAdding(false);
    setIsEditing(true);
    setInputValue('');
  };


  const handleEditDeck = id => {
    if (!decks || decks.length === 0) return;
    const [deck]: Array<DeckData | null> | undefined = decks.filter(deck => deck?.id === id);
    if (!deck) return;

    setSelectedDeck(deck);
    setIsDashboard(false);
    setIsBuilding(true);
    setIsCollapsed(true);
    setIsAdding(false);
    setIsEditing(false);
    setIsDeckEditing(true);
    setInputValue('');
  };

  const floatButtonStyle = isXS ? { left: 'calc(.5em + 3px)', bottom: 8, background: '#fff0' } : { left: 16, bottom: 16, background: '#fff0' };

  return (
    <Layout
      style={{ background: 'unset' }}
    >
      {!isEditing && !isBuilding ?
        <FloatButton.Group
          open={isSearchOpen}
          trigger="click"
          style={floatButtonStyle}
          className='floatButtonMenu'
          rootClassName='floatButtonRoot'
          icon={<SearchOutlined />}
          shape="square"
          badge={{
            count: inputValue ? filteredList.length : null,
            size: 'default',
            className: 'glassybadge',
            color: '#00000075',
          }}
          onClick={(e) => {
            setIsSearchOpen(!isSearchOpen)
          }}
        >
          <Space>
            <Search
              className='searchBarInput'
              addonAfter={false}
              placeholder="Input search text"
              allowClear
              autoFocus={true}
              size='middle'
              defaultValue={inputValue ? inputValue : null}
              onSearch={
                (value: string) => {
                  setIsSearchOpen(false)
                  setSelectedCard(null)
                  setIsCollapsed(true)
                  setInputValue(value)
                }
              }
              style={{ display: 'flex', alignItems: 'center', width: 320 }}
            />
          </Space>
        </FloatButton.Group> :
        null
      }
      <Header
        cardsCount={cards.length}
        handleEdit={handleEdit}
        isAdding={isAdding}
        isBuilding={isBuilding}
        isCollapsed={isCollapsed}
        isDashboard={isDashboard}
        isEditing={isEditing}
        isMenuOpen={isMenuOpen}
        isXS={isXS}
        selectedCard={selectedCard}
        setIsAuthenticated={setIsAuthenticated}
        setInputValue={setInputValue}
        setIsAdding={setIsAdding}
        setIsBuilding={setIsBuilding}
        setIsCollapsed={setIsCollapsed}
        setIsDashboard={setIsDashboard}
        setIsDeckEditing={setIsDeckEditing}
        setIsEditing={setIsEditing}
        setIsMenuOpen={setIsMenuOpen}
        setSelectedCard={setSelectedCard}
      />
      <Layout
        hasSider
        style={{ background: 'unset', minHeight: 'calc(100vh - 46px)' }}
      >
        <Content style={{marginBottom: isXS ? '60px' : 'unset'}}>
          {!isAdding && !isEditing && !isBuilding && (
            <CardsList
              cards={cards}
              handleEdit={handleEdit}
              setSelectedCard={setSelectedCard}
              inputValue={inputValue}
              isCollapsed={isCollapsed}
              filteredList={filteredList}
              setFilteredList={setFilteredList}
              isXS={isXS}
              setIsMenuOpen={setIsMenuOpen}
            />
          )}
          {isAdding && (
            <Add
              cards={cards}
              setCards={setCards}
              setIsAdding={setIsAdding}
            />
          )}
          {isEditing && (
            <Edit
              cards={cards}
              selectedCard={selectedCard}
              setCards={setCards}
              setIsEditing={setIsEditing}
              setIsAdding={setIsAdding}
              setIsDashboard={setIsDashboard}
              openNotificationWithIcon={openNotificationWithIcon}
              isXS={isXS}
              setIsMenuOpen={setIsMenuOpen}
            />
          )}
          {isBuilding && !isDeckEditing && (
            <DeckList
              decks={decks}
              selectedCard={selectedCard}
              setCards={setCards}
              cards={cards}
              isXS={isXS}
              selectedDeck={selectedDeck}
              setSelectedDeck={setSelectedDeck}
              isDeckEditing={isDeckEditing}
              setIsDeckEditing={setIsDeckEditing}
            />
          )}
          {isBuilding && isDeckEditing && (
            <DeckEdit
              decks={decks}
              selectedCard={selectedCard}
              setCards={setCards}
              cards={cards}
              setDecks={setDecks}
              isXS={isXS}
              openNotificationWithIcon={openNotificationWithIcon}
              selectedDeck={selectedDeck}
              setSelectedDeck={setSelectedDeck}
              isDeckEditing={isDeckEditing}
              setIsDeckEditing={setIsDeckEditing}
              setInputValue={setInputValue}
              setIsAdding={setIsAdding}
              setIsBuilding={setIsBuilding}
              setIsCollapsed={setIsCollapsed}
              setIsDashboard={setIsDashboard}
              setIsEditing={setIsEditing}
              setIsMenuOpen={setIsMenuOpen}
              setSelectedCard={setSelectedCard}
              handleEdit={handleEditDeck}
              inputValue={inputValue}
              isCollapsed={isCollapsed}
              filteredList={filteredList}
              setFilteredList={setFilteredList}
            />
          )}
        </Content>
        <Drawer
          placement='right'
          onClose={() => {
            setIsCollapsed(!isCollapsed)
            setSelectedCard(null)
          }}
          closable={true}
          open={(!isCollapsed && !isEditing && selectedCard != null)}
          className='glassiphy'
          mask={false}
          zIndex={2}
          height={'bottom'}
          width={'320px'}
          afterOpenChange={(open) => {
            if (!isCollapsed && document.body.querySelector('.ant-drawer-body')) {
              document.body
                .querySelector('.ant-drawer-body')
                .scrollTo(0, 0);
            }
          }}
          bodyStyle={{ padding: '0', background: '#fff0' }}
          contentWrapperStyle={{ background: '#fff0', boxShadow: 'unset' }}
          style={{ background: '#ffffff75' }}
          rootStyle={{ background: 'unset', zIndex: '0' }}
          headerStyle={{ background: '#fff0', padding: '16px 0 16px 0', alignItems: 'end', flexDirection: 'column' }}
        >
          <SiderContent
            handleEdit={handleEdit}
            selectedCard={selectedCard}
            setIsCollapsed={setIsCollapsed}
            setSelectedCard={setSelectedCard}
            setIsMenuOpen={setIsMenuOpen}
          />
        </Drawer>
      </Layout>
      <Footer
        hidden={(useMobileCheck() && !isCollapsed) ? true : false}
        className='glassyfooter'
        style={{
          textAlign: 'center',
          bottom: '0',
          fontSize: '12px',
          zIndex: 1,
          maxWidth: isCollapsed || isEditing ? '100vw' : 'calc(100vw - 320px)',
        }}
      >
        <FooterContent />
      </Footer>
    </Layout>
  );
};

export default Dashboard;
