import React from 'react';
import {
  Col,
  List,
  Row,
} from 'antd';
import DeckItem from './DeckItem';
import { DeckData } from '../../type';

const DeckList: React.FC<any> = ({
  decks,
  selectedCard,
  setCards,
  isXS,
  cards,
  selectedDeck,
  setSelectedDeck,
  isDeckEditing,
  setInputValue,
  setIsAdding,
  setIsBuilding,
  setIsCollapsed,
  setIsDashboard,
  setIsDeckEditing,
  setIsEditing,
  setIsMenuOpen,
  setSelectedCard,
  openNotificationWithIcon,
}) => {
  return (
    <Row>
      <Col
        span={24}
        style={{ maxWidth: true ? '100vw' : 'calc(100vw - 320px)',
          padding: '16px' }}
      >
        <List
          loading={false}
          grid={{
            gutter: 6,
          }}
          style={{ paddingTop: '.2em', margin: 0 }}
          className="cardlist"
          dataSource={decks}
          pagination={{
            size: !isXS ? 'small' : 'small',
            total: decks.length,
            pageSize: 12,
            pageSizeOptions: [12],
            style: {
              paddingRight: !isXS ? '16px' : '8px',
              paddingLeft: !isXS ? '16px' : '8px',
              paddingBottom: !isXS ? '24px' : '16px',
              paddingTop: !isXS ? '16px' : '8px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              textAlign: 'center',
            },
            position: 'bottom',
            showTitle: false,
            responsive: true,
            hideOnSinglePage: true,
          }}
          renderItem={(item: DeckData) => {
            return <DeckItem
              item={item}
              cards={cards}
              selectedDeck={selectedDeck}
              setSelectedDeck={setSelectedDeck}
              setIsDeckEditing={setIsDeckEditing}
              setInputValue={setInputValue}
              setIsAdding={setIsAdding}
              setIsBuilding={setIsBuilding}
              setIsCollapsed={setIsCollapsed}
              setIsDashboard={setIsDashboard}
              setIsEditing={setIsEditing}
              setIsMenuOpen={setIsMenuOpen}
              setSelectedCard={setSelectedCard}
              openNotificationWithIcon={openNotificationWithIcon}
          />}}
        />
      </Col>
    </Row>
  );
};

export default DeckList;