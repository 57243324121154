import { EditOutlined } from "@ant-design/icons";
import { List, Card, ConfigProvider, Button, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";

const DeckItem = ({
  item,
  cards,
  setSelectedDeck,
  setIsDeckEditing,
  selectedDeck,
  setInputValue,
  setIsAdding,
  setIsBuilding,
  setIsCollapsed,
  setIsDashboard,
  setIsEditing,
  setIsMenuOpen,
  setSelectedCard,
  openNotificationWithIcon,
}) => {
  const cardSummonerA = cards.find((card) => Number(card.id) === Number(item.list[2]))
  const cardSummonerB = cards.find((card) => Number(card.id) === Number(item.list[3]))

  const summonerImg = {
    a: '/img/' + cardSummonerA?.image.replace('.png', '.webp'),
    b: '/img/' + cardSummonerB?.image.replace('.png', '.webp')
  }

  const card = (<Card
    className="glassiphy"
    loading={!item}
    bodyStyle={{ 
      padding: "8px", 
      margin: '8px',
      maxHeight: '240px',
      width: '320px'
    }}
    cover={
      <div className="gallery">
        <img src={summonerImg.a} alt={item.name + ' - Summoner A'} />
        <img src={summonerImg.b} alt={item.name + ' - Summoner B'} />
      </div>
    }
  >
    <Meta 
      title={item.name} 
      description={item.meta.slice(0, 120)} 
      style={{margin: '8px', height: '120px'}}
    />
    <Row style={{width: '100%', display: "flex", justifyContent: 'end'}}>
      <Button 
        onClick={() => {
          setSelectedDeck(item)
          setIsDeckEditing(true)
        }} 
        size="middle"
      >
        <EditOutlined />
      </Button>
    </Row>
  </Card>)

  return (<ConfigProvider
    theme={{
      components: {
        Image: {
          previewOperationHoverColor: "rgba(255, 255, 255, 0.35)"
        },
      },
    }}
  >
    <List.Item
      style={{
        display: "flex",
        justifyContent: 'space-evenly',
        listStyleType: 'none',
        margin: '4px',
      }}
    >
      {card}
    </List.Item>
  </ConfigProvider>);
};

export default DeckItem;